import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function CorporateCommunications() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Crafting corporate PR that drive positive perceptions.</Card.Title>
         <Card.Text><p>Companies today have to do more than deliver a product
          or service. They have to take a stand on important issues — that’s
          what customers, partners, and employees expect. But communicating your
          company’s principles and opinions is a delicate challenge, always
          requiring the right words and tone.</p>
          <p>Balancing thought leadership and corporate reputation management,
          Global Partners designs corporate communications that drive positive
          perception and reach key goals.</p>
          <p>We work to build awareness, shape stakeholder opinions, advocate
          for important issues, engage customers and employees, and protect
          reputations — in good times and bad.</p></Card.Text>
            <Card.Img className="nyash" src={"/static/media/Corporate-Communications-116369.jpg"}/>
         <Card.Text>
           <p>Our corporate communications experts partner closely with your
           company’s leaders and offer a full range of capabilities,
           including:</p>
           <ul>
            <li>Corporate reputation management strategy and planning</li>
            <li>Corporate PR</li>
            <li>Executive communications and content development</li>
            <li>Investor relations</li>
            <li>Messaging development and workshops</li>
            <li>Crisis planning, management, and communications</li>
            <li>Diversity, Equity and Inclusion (DEI) counsel, strategy, and programmes</li>
            <li>Public affairs programmes</li>
            <li>Corporate social responsibility/ESG strategy and programmes</li>
            <li>Employee communication and engagement programmes</li>
            <li>Web traffic and lead generation</li>
            <li>Sales enablement</li>
            <li>Brand sentiment, reputation analysis, and industry awareness</li>
            <li>Social relevance, sentiment, and engagement</li>
            <li>Media relations and media coverage</li>
            <li>Thought leadership/content programmes</li>
            <li>Change communications</li>
            <li>Speaking engagements and speechwriting</li>
            <li>Client testimonials</li>
            <li>Event design, planning, and activation</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Pioneering leadership in the wellness hospitality industry.</Card.Title>
           <Card.Text>Westin Hotels & Resorts recognized an incredible
           opportunity — to forge a path for hospitality in the wellness
           space and, of course, to assert itself as the global leader in
           healthy leisure and business travel. But to disrupt the wellness
           industry by inserting hospitality into the dialogue, Westin needed
           a smart, highly strategic plan and creative, effective executions.
           That’s why it turned to Global Partners.
           </Card.Text>
           <Card.Text>Leveraging our deep relationships and know-how in fitness,
           food, outdoor, and travel, Global Partners carved a path for leisure and business
           travelers who prioritize their well-being on the road.</Card.Text>
           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Insert hospitality into the wellness dialogue and make Westin synonymous with health and well-being in travel.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Wellness</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>HSMAI Adrian Awards: Best in Show</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/Westin-gate-100.jpg"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Text>First, we trend-jacked key moments in the wellness
        industry. For example, we stole the “sweatworking” trend (the
          idea that consumers are networking over a workout or at a
          juice bar). And for World Sleep Day, we turned FOMO into JOMO
          (the Joy of Missing Out) — an idea rooted in Westin’s
          prioritization of an exceptional night’s sleep.</Card.Text>
        <Card.Text>
         We also brought outdoor wellness to the forefront through Westin’s
         Adventure Concierges, across surf, hiking, run, cycling, golf,
         tennis, and more.
        </Card.Text>
        <Card.Text>
         Second, we launched wellness-based programs and partnerships that
         catapulted Westin into the news cycle through a lens of well-being.
         These included a Westin x Peloton partnership that put Peloton bikes
         in Westins across the country.
        </Card.Text>
        <Card.Text>
          Then, we created an upcycling program to transform hotel bed linens
          into children’s pajamas to be donated to children in need. This program
          elevated sustainability and giving back into the wellness vernacular,
          and it led to the collection of 30,000 pounds of linens from 50 hotels
          in five months — and coverage that included 300+ placements in North America alone.
        </Card.Text>
        <Card.Text>
          Westin’s wellness messaging made headlines in Men’s Health, Runner’s
          World, SELF, Well and Good, Shape, and more, as well as in national
          news and business outlets including Associated Press, USA Today, Fast
          Company, Washington Post, AdWeek, AdAge and The New York Times.
        </Card.Text>
        <Card.Text>
         The work helped Westin secured over 2 billion UVMs in 2019, and it
         won the 2019 Best in Show Award at the HSMAI (Hospitality Sales and
           Marketing Association International) Adrian Awards.
        </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default CorporateCommunications;
