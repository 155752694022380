import React from "react";
import { createRoot } from 'react-dom/client';
import './App.scss';
import App from "./components/App";

//import MainContainer from './MainContainer';

const MainApp = () => (
  <React.Fragment>
    <App />
  </React.Fragment>
);

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MainApp />);
