import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function TravelTourism() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Destined to deliver great experiences.</b></Card.Title>
            <Card.Text>Unforgettable experiences, wonderful memories, and once-in-lifetime
            adventures — that’s what travellers seek, and it’s what travel brands and
            destinations deliver. As a renowned travel PR and marketing agency, Global Partners
            makes meaningful connections between your hospitality brand and the media you
            want to be taking notice. We do that by leveraging a worldwide network of media
            relationships and industry contacts, and by telling creative stories about your
            brand that hit the mark, make a difference, generate compelling column inches,
            and directly impacting your bottom line.</Card.Text>
          <Card.Text>
            The beauty of our diverse team is that we work with boutique hotels, tour operators,
            luxury hotel brands, in-demand destinations, cruise lines, airlines, travel products
            and more, which help ensure that our programs are customised to your unique needs. We
            believe deeply in productive purpose, and we partner with like-minded travel businesses
            motivated to meet sustainable goals. Global Partners is powered by people driven by their
            passion for travel, at the top of their game, who feel that travel and tourism should be
            a force for good in the 21st Century.
          </Card.Text>
          <Card.Text><b>Luxury Travel</b>
          </Card.Text>
          <Card.Text>Our award-winning work in luxury travel ranges from independent boutique hotels
          to private islands, from safari operators to highly ranked cruise companies, private aviation
          to world-class wellness spas. We have a talented team publicist who customise high-performing
          plans and manage A-list media campaigns to drive awareness and business to our clients. We also
          work with travel adjacent brands such as tech products, luggage, real estate, entertainment, etc.
          </Card.Text>

          </Card.Body>
          </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Img className="nyash" src={"/static/media/Travel-Flipped-200.jpg"}/>
            <Card.Text>
             <b>Destinations</b>
            </Card.Text>
            <Card.Text>We have the distinction to work with some of the world’s most desirable destinations
            around the globe. Global Partners is hired by countries, states and cities to showcase their
            exceptional offerings to drive tourism and lead economic impact campaigns. The media know that
            Global Partners work tirelessly with tourism ministers and DMCs to effectively increase visibility.
            </Card.Text>
            <Card.Text>
            <b>Hotels, Resorts & Spas</b>
            </Card.Text>
            <Card.Text>Our team is deeply verse and rooted in the hospitality industry therefore uniquely able
            to work alongside and for some of the world’s best and most visible hotel and wellness brands. We
            speak the language of a hotelier and strive to not just promote a property or brand but to amplify
            its reputation and enhance its business. Our senior team regularly attend industry events and conference,
            keeping in front of industry trends and ultimately informing client campaigns.
            </Card.Text>
            </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
          <p>Our B2B capabilities include economic development, MICE market promotion, cruise market and product development.</p>
          <p>Global Partners&apos; tourism practice spans three continents, including major media hubs in New York,
          Los Angeles, Hong Kong, Shanghai, London and Munich. And we offer a full range of travel agency marketing services, including:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>Public relations</li>
           <li>Branding and repositioning</li>
           <li>Issues management</li>
           <li>Digital and influencer marketing</li>
           <li>Trade relations & representation</li>
           <li>High-profile launches</li>
           <li>Event activations</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Belize-hero-image-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Encouraging European’s to ‘Grab Life&apos;</b></Card.Title>
          <Card.Text>
            Belize. A stunning tourist destination filled with beauty, history, and adventure. Yet, short on European tourism. Belize lacked understanding of their European audiences thus required deep persona research and a refreshed messaging and media plan.
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>To drive awareness and bookings to Belize from UK, France and German tourists through an integrated campaign.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Travel & Tourism</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Belize-Goal-image-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           Belize had a perception of who their European target audiences were
           but needed help in strategically redefining and expanding them to
           increase tourism. We conducted a 4 step approach:
           <ul>
             <li>Understanding Belize’s existing digital audience: We audited Belize’s current social followers and those already visiting their website.</li>
             <li>Understanding geographic differences: We identified the typical ‘traveler’ from the UK, Germany and France, and identified similarities and differences between them.</li>
             <li>Redefining audience segments: From the insights already pulled we redefined audience segments and priorities, adding groups such as Eco-living Travelers.</li>
             <li>Deep-diving personas: Using primary and secondary research to get into the shoes of our segments we pulled insights into who they are, where they are, and what resonates with them. This included surveying 1,500 respondents (split across the UK, Germany and France) within the target demographic (18-64-year-olds in the middle-to-high income brackets who have a propensity to take long-haul holidays). This research helped inform a better understanding of traveler behaviors, their perception of Belize and they type of messaging that is most likely to resonate.</li>
             </ul>
             </Card.Text>
             <Card.Text>From our audience insights a fresh paid media and PR strategy were constructed
             utilizing Belize’s key message to ‘Grab life’, and emphasize key selling points
             including the Belize Barrier Reef, cultural aspects such as the Mayan sites, and
             activities like zip lining. Our insights recommended a new structuring of audiences
             in paid plans, new channels to reach relevant audience groups, adapted messaging and
             imagery to resonate with each group, and more.</Card.Text>
             <Card.Text>The campaign has been a great success achieving a 85% increase in new users to the
             website and a 30% increase year-on-year from paid activity.</Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Travel Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         Travellers have countless options — so travel and tourism companies need a compelling message, clear market position, and engaging personality to stand out. We use detailed research and vast experience to help your company build its brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Travel Content Marketing</Accordion.Header>
       <Accordion.Body>
         Driving traffic to your site takes relevant content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Travel Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Travel Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your travel and tourism marketing efforts. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Travel Influencer Marketing</Accordion.Header>
       <Accordion.Body>
         Influencer marketing can be especially effective for travel companies and destinations, but it takes finding the influencer whose personality and style are the perfect fit. We have close relationships with numerous influencers who can help you hit your goals.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Travel Public Relations</Accordion.Header>
       <Accordion.Body>
         Travel and tourism companies, as well as destinations, can benefit greatly by having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and growing your brand with key audiences.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>Travel Research & Insights</Accordion.Header>
       <Accordion.Body>
         Navigating the ever-changing landscape of travel and tourism takes rock-solid research and insights. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
       <Accordion.Header>Travel Trade Representation</Accordion.Header>
       <Accordion.Body>
         Our expertise in destination representation, span the globe. As the in-market liaison for international tourist offices, our experience is unrivalled. Our expert teams integrate your sales, marketing and communications efforts while leveraging the full range of services afforded by being part of Global Partners.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default TravelTourism;
