import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function DigitalMarketing() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Creating digital marketing strategies that drive performance.</Card.Title>
         <Card.Text><p>Brands and organisations are increasingly turning to
         digital marketing as an overarching strategy for building brand
         awareness, acquiring new customers and growing sales revenues.
         To win in your markets and sectors, you need a fully integrated
         marketing and communications campaigns that deliver the right messages,
         to the right people, in the right place, at the right time.</p>
          <p>Global Partners can helps you to tailor a digital marketing strategy that will deliver on your strategic objectives.</p>
          <p>Pulling from our dedicated teams of digital marketing experts,
          covering SEO (Search Engine Optimisation), Paid Advertising, Search
          Advertising, Social Media Optimisation and more, we create data-driven,
          multichannel marketing and communications campaigns. They’re strategically
          designed to build brand visibility, boost audience engagement, generate
          leads, and drive business results.</p></Card.Text>
            <Card.Img className="nyash" src={"/static/media/Digital-Marketing-200.jpg"}/>
         <Card.Text>
           <p>Global Partners&apos; digital marketing experts seamlessly
           integrate the latest digital marketing tactics into our integrated
           marketing campaigns and offer a wide array of capabilities, including:</p>
           <ul>
            <li>B2B and B2C digital campaigns</li>
            <li>SEO (Search Engine Optimisation)</li>
            <li>PPC and Paid Advertising</li>
            <li>SEM and Search Advertising</li>
            <li>Social Media Advertising</li>
            <li>SMO (Social Media Optimisation)</li>
            <li>Digital content strategy and production</li>
            <li>Google Analytics, Custom Dashboards and Digital Strategy Management</li>
            <li>Landing page and website UX/UI design and development</li>
            <li>Consultation on/management of marketing automation, CRM, MarTech platforms</li>
            <li>Social media strategy and execution: social takeovers; curated social content; custom hashtags</li>
            <li>Measurement of social engagement, audience, and reach</li>
            <li>Media planning and management including paid search, social and banner ads</li>
            <li>Influencer engagement and partnerships</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Telling the 150-year story of one of the most iconic brands for more than 30 years.</Card.Title>
           <Card.Text>For more than 30 years, Global Partners has worked
           with the Jack Daniel’s Distillery and parent company Brown-Forman
           as a trusted partner. The scope of services we provide to the distillery
           are many and touch every department within our agency, from media
           relations, digital, social media, crisis communications, video, event
           management among other work. Our decades of involvement have led to
           one of our managing partners being named brand historian. Over the years,
           we have secured tens of millions of impressions for the brand through
           earned and social media while ensuring the coverage garnered reflects
           the premium nature of this iconic Tennessee whiskey. Through it all, two
           things have remained consistent: The Brand + The PR Agency.
           </Card.Text>
           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Continue to champion the story of this iconic brand while stewarding connections with existing and new “friends” of the brand.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Consumer Brand</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/Jack-Daniels-1.png"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Text>
         <p><b>Launched New Brands:</b> Since 1987, the Jack Daniel’s
         account team has made the journey from 3 brands to over a dozen.
         After launching Gentleman Jack, we introduced Single Barrel,
         Tennessee Fire, Tennessee Honey, Sinatra, most recently, Tennessee
         Apple, and more.</p>
         <p><b>Increased Sales:</b> Jack Daniel’s Old No. 7 is the #1-selling
         whiskey label in the world that is available in more than 170 countries,
         beginning with 5MM cases sold annually to now over 20MM.</p>
         <p><b>Podcasts & Documentary:</b> We launched a podcast, Around the Barrel,
         which ranks in the top 20% of iTunes podcast downloads. Additionally,
         produced a first-ever feature length brand documentary, released on
         April 16, 2021 on AppleTV.</p>
         <p><b>Lifelong Partnership:</b> Global Partners is the official brand historian and we train the future leaders of Jack Daniel’s.</p>
         </Card.Text>
        </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default DigitalMarketing;
