import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function PublicRelations() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Telling brand stories in memorable, masterful ways.</Card.Title>
         <Card.Text><p>What makes a public relations campaign successful? When your
         product or service aligns with what your audience wants and needs — and you
         communicate that unique value to them effectively.</p>
          <p>Global Partners will help you do it, using the smartest, most strategic public relations solutions found anywhere.</p>
          <p>When it comes to earned media, we take a data-driven approach to developing creative storytelling angles, coupled with a deep understanding of the media and influencers you need to reach, to ensure your message breaks through.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/public-relations-200.jpg"}/>
         <Card.Text>
           <p>The Global Partners team has established long-term, highly engaged relationships with media, and we have deep influencer and business partnerships around the world.</p>
           <p>We are sought out by the media for story ideas and influencers for partnerships — meaning our clients are in demand, too. Our reputation as a dependable conduit for news and on-trend content makes us a go-to source for the media and allows our clients to be seen and heard.</p>
           <p>Global Partners&apos; extensive suite of public relations services includes:</p>
           <ul>
            <li>Media relations</li>
            <li>Investor relations</li>
            <li>Events and experiential</li>
            <li>Social media</li>
            <li>Crisis communications</li>
            <li>Issues management</li>
            <li>Media training</li>
            <li>Message development</li>
            <li>Reputation management</li>
            <li>Market research</li>
            <li>Employee relations</li>
            <li>Community relations</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>

    </Container>
  )
}

export default PublicRelations;
