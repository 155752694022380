import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Manufacturing() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/Manufacturing-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Accelerating growth by creating value.</b></Card.Title>
            <Card.Text><p>B2B companies in the manufacturing industry want two key
            advantages — to be an indispensable resource for their customers and to
            discover opportunities before their competitors. Global Partners has helped
            our B2B clients to do both, for nearly four decades.</p>
             <p>As a B2B manufacturing marketing agency, our proven marketing and
             communications campaigns effectively launch new products, revive old brands,
             and yield measurable results that accelerate growth, regionally and globally.</p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Bosch-hero-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Launching a new product into the digital world.</b></Card.Title>
          <Card.Text><p>With a new product to launch in a crowded marketplace, Bosch Packaging Technology
          (now Syntegon) needed to create brand awareness, generate leads online and increase market share
          globally outside of traditional trade shows. To facilitate the successful launch of the world’s
          fastest vertical bagger with Doy Zip functionality, Global Partners masterminded and executed an
          integrated global marketing campaign to target and connect with key decision-makers and ultimately
          drive sales.</p>
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Facilitate the successful launch of the latest Bosch packaging
         machine, generating global sales and increasing market share.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Manufacturing</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Bosch-goal-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           <p><b>Compelling brand story</b></p>
           <p>Global Partners helped Bosch Packaging Technology establish a
           differentiated messaging platform to position Doy Zip from Bosch as
           the world fastest vertical bagger that also offers a premium look,
           helping brands to attract consumers at the point-of-sale. The new
           brand story strongly distinguished Doy Zip technology from competitors,
           capturing the attention and interest of prospects.</p>
           </Card.Text>
           <Card.Text>
           <p><b>Robust sales approach that lasts</b></p>
             <p>Multi-lingual, multi-channel sales and marketing campaign not only
             generated strong interest among target audiences, but also drove visitors
             to the <a href="https://www.doyzip.com/">Doy Zip microsite</a> – the heart of the campaign and sales generation
             machine. With over 400 global leads generated via the microsite in a 12-month
             period, Global Partners created a well-oiled lead generation tool that will deliver for
             years to come.</p>
            </Card.Text>
            <Card.Text>
            <p><b>Outside-the-box thinking</b></p>
              <p>Engineer-focused, Global Partners challenged the traditional sales approach at Bosch Packaging Technology via extensive
              training of sales teams to target both decision-making groups – engineers and marketers. Global Partners sales experts
              advised on how to best leverage the powerful positioning and messaging platform to gain traction with key decision-makers
              with an outside-the-box sales presentation template.</p>
             </Card.Text>
             <Card.Text>
             <p><b>Spreading the word</b></p>
               <p>A well-focused, integrated communications campaign was launched to boost interest and leads. Multi-faceted public relations,
               thought leadership activities and PPC campaign in English, German, Spanish, French and Chinese drove traffic to the microsite
               with over 20,000 visits in the first year and captured the market’s interest.</p>
             </Card.Text>
             <Card.Text>
               The digital possibilities of launching and promoting a product are limitless. Partnering with an experienced team will allow
               you to take shortcuts to effective B2B product launch strategies and equip your sales teams with a continuous flow of high-quality
               leads.
             </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

      <Card className="main_scene2">
       <Row>

       <Col xs={12} md={6}>
        <Card.Body>
         <Card.Text>
        <p>We have deep experience, spanning a diverse range of sectors that include: automotive, aerospace, chemical, construction, energy, food & beverage, health, materials, packaging, oil & gas, automation, safety, and sustainability.</p>
        <p>We follow four core tenets to fuel our success as a B2B digital marketing agency for manufacturers and drive results for our clients:</p>
        </Card.Text>
        </Card.Body>
       </Col>
       <Col xs={12} md={6}>
        <Card.Body>
        <Card.Text>
        <ul>
         <li>Frame and communicate the unique business value – not just products and services — to achieve compelling differentiation in the marketplace</li>
         <li>Connect with decision-makers above the purchasing level so price becomes a detail, not the major consideration</li>
         <li>Close the loop between marketing and sales (two sides of the same coin) to shorten sales cycles</li>
         <li>Accelerate growth in niche markets, locally/globally</li>
        </ul>
        </Card.Text>
        </Card.Body>
       </Col>

      </Row>
     </Card>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Manufacturing Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         The manufacturing field is crowded, and standing out from the competition takes a compelling message, clear market position, and engaging personality. We use detailed research and vast experience to help your manufacturing company to build its brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Manufacturing Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Manufacturing Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customised to your manufacturing company. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Manufacturing Public Relations</Accordion.Header>
       <Accordion.Body>
         Manufacturers of all kinds can benefit greatly by having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and growing your B2B manufacturing brand in your key markets.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Manufacturing Research & Insights</Accordion.Header>
       <Accordion.Body>
         Manufacturing is a field that’s constantly growing and evolving, and success requires research and insights your business can rely on. To inform the strategy and establish measurement of the work we deliver for our clients, we rely on FINN Global Intelligence – our research & business intelligence group. Using an array of research instruments, Global Intelligence conducts the intensive research required to provide actionable insights and recommendations that deliver measurable results on a global scale.
       </Accordion.Body>
      </Accordion.Item>

     </Accordion>

    </Container>
  )
}

export default Manufacturing;
