import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Wellness() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/wellness-hero-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Taking care of what’s important.</b></Card.Title>
            <Card.Text>Wellness is no longer a buzzword or a trend. It’s a way of
            life that inspires consumers by the millions — and Global Partners is
            committed to helping wellness efforts succeed for our clients and communities,
            by serving as a health and wellness marketing agency resource.</Card.Text>
            <Card.Text>We help brands create a wellness narrative with impact, whatever
            their industry — from foods and beverages at groceries and fitness brands
            that drive workout trends, to the products that define personal care routines
            and the technologies that help make healthier habits.
            </Card.Text>
            <Card.Text>Our global Wellness Collaborative partners with and elevates brands
            that support consumer health, wellness, and longevity. We lend those brands an
            unmatched depth of knowledge and expertise to understand what affects their
            consumers’ behaviors and choices.
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
          <p>The Wellness Collaborative brings together experts from our Consumer, Health, Travel, Lifestyle, and Tech practices. They are leaders and advocates for healthy living and eating, fitness, outdoor and adventure travel, and sustaining our planet through better use of global resources.</p>
          <p>Through Global Partner’s ongoing partnership with The Global Wellness Institute, we drive success for brands by providing insights and data related to trends that influence consumer journeys toward positive behaviors. We offer deep expertise in these areas:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>Health</li>
           <li>Consumer</li>
           <li>Travel</li>
           <li>Lifestyle</li>
           <li>Sports</li>
           <li>Fitness</li>
           <li>Food and beverage</li>
           <li>Fashion markets</li>
           <li>Employing digital tools and technologies</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Westin-Hero-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Pioneering leadership in the wellness hospitality industry.</b></Card.Title>
          <Card.Text>
            Westin Hotels & Resorts recognized an incredible opportunity — to forge a path for hospitality
            in the wellness space and, of course, to assert itself as the global leader in healthy leisure
            and business travel. But to disrupt the wellness industry by inserting hospitality into the dialogue,
            Westin needed a smart, highly strategic plan and creative, effective executions. That’s why it turned
            to Global Partners.
           </Card.Text>
           <Card.Text>
             Leveraging our deep relationships and know-how in fitness, food, outdoor, and travel, Global Partners
             carved a path for leisure and business travelers who prioritize their well-being on the road.
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Insert hospitality into the wellness dialogue and make Westin synonymous with health and well-being in travel.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Wellness</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>HSMAI Adrian Awards: Best in Show</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Westin-goal-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           First, we trend-jacked key moments in the wellness industry. For example, we stole the “sweatworking” trend (the idea that consumers are networking over a workout or at a juice bar). And for World Sleep Day, we turned FOMO into JOMO (the Joy of Missing Out) — an idea rooted in Westin’s prioritization of an exceptional night’s sleep.
           </Card.Text>
           <Card.Text>
             We also brought outdoor wellness to the forefront through Westin’s Adventure Concierges, across surf, hiking, run, cycling, golf, tennis, and more.
            </Card.Text>
            <Card.Text>
              Second, we launched wellness-based programs and partnerships that catapulted Westin into the news cycle through a lens of well-being. These included a Westin x Peloton partnership that put Peloton bikes in Westins across the country.
             </Card.Text>
             <Card.Text>
              Then, we created an upcycling program to transform hotel bed linens into children’s pajamas to be donated to children in need. This program elevated sustainability and giving back into the wellness vernacular, and it led to the collection of 30,000 pounds of linens from 50 hotels in five months — and coverage that included 300+ placements in North America alone.
             </Card.Text>
          <Card.Text>
            Westin’s wellness messaging made headlines in Men’s Health, Runner’s World, SELF, Well and Good, Shape, and more, as well as in national news and business outlets including Associated Press, USA Today, Fast Company, Washington Post, AdWeek, AdAge and The New York Times.
          </Card.Text>
          <Card.Text>
            The work helped Westin secured over 2 billion UVMs in 2019, and it won the 2019 Best in Show Award at the HSMAI (Hospitality Sales and Marketing Association International) Adrian Awards.
          </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Wellness Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear mission, and engaging personality — those are the keys to raising awareness of your health and wellness brand. We’ll help you do it, through a combination of detailed research and vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Wellness Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your health and wellness initiatives. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Wellness Influencer Marketing</Accordion.Header>
       <Accordion.Body>
         Wellness influencers can be especially effective, but they have to be credible and highly trustworthy — and have a personality and style that match your brand. We have close relationships with numerous influencers who can help you hit your goals.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Wellness Public Relations</Accordion.Header>
       <Accordion.Body>
         Health and wellness initiatives and brands can greatly benefit from having strong, genuine, lasting relationships with the media. We’ll help you build those relationships, nurturing new networks and increasing your awareness with audiences.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Wellness Research & Insights</Accordion.Header>
       <Accordion.Body>
         Building a trusted wellness business and brand takes leveraging the newest, most reliable research and insights. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default Wellness;
