import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function Advertising() {
  return (
    <Container>
     <Row>

     <Col>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>work that connects with the heart.</Card.Title>
         <Card.Text>
          <p>
           Advertising isn’t just about capturing mindshare and turning
           heads — it’s about winning hearts. Great brands use great ads
           to compel people to sit up, listen, and invest in the product
           or service. But where do those brands turn for great ad ideas
           and execution?
          </p>
          <p>
           They turn to Global Partners. Using custom-designed, creative
           storytelling and big-idea thinking, we’re an advertising agency
           that helps clients find their authentic voice, showcase the heart
           and soul of their brand, and connect with their audiences.
          </p>
          </Card.Text>
         </Card.Body>
        </Card>

       </Col>


        <Card>
        <Card.Body>
            <Card.Img className="nyash" src={"/static/media/HGS-hero-1.png"}/>
         </Card.Body>
        </Card>

       </Col>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Differentiate and drive awareness for a global customer experience (CX) provider competing in the crowded digital transformation consulting space.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Professional Services</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>Platinum MarCom: Advertising</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/HGS-goal-1.png"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Title><p>Assessing the landscape to hone our message</p></Card.Title>
        <Card.Text><p>Global Partners attacked this creative assignment by
        first researching and developing personas, pain points,
        competitive messages, and marketing content in the field.</p>
        <p>
         We found that most players in the industry, including HGS, talked about digital transformation in their communications — and that while buyers knew the phrase, most thought it sounded vague and expensive. But everyone in the C-suite understood that better customer experience was an imperative.
        </p>
        <p>
         Research also showed that the C-suite (CIO, CMO, and CDO) operated in silos around customer experience and did not talk openly about the “issue.”
        </p></Card.Text>
      </Card.Body>

      <Card.Body>
        <Card.Title><p>“Let’s talk about CX”</p></Card.Title>
        <Card.Text>
        <p>Enter our creative team’s solution: The “Let’s talk about CX” campaign, which paired a buzz-generating message with a paid media campaign targeting the retail industry. It generated 15,708,182 impressions and 59,593 website clicks in one month. The parent company was impressed enough with the creative to adopt the Better CX theme as its own.
        </p>
        <p>
         We are now evolving the campaign to customize and enhance lead-gen across key performance groups.
        </p>

        </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default Advertising;
