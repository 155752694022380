import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function TravelTradeRepresentation() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Representing smooth travels across the globe.</Card.Title>
         <Card.Text>Our expertise in destination representation,
         span the globe. As the in-market liaison for international
         tourist offices, our experience is unrivalled. Our expert
         teams integrate your sales, marketing and communications
         efforts while leveraging the full range of services afforded
         by being part of Global Marketing Partners.</Card.Text>
            <Card.Img className="nyash" src={"/static/media/Jordan-Hero-100.jpg"}/>
         <Card.Text>
           We are highly experienced in offering ‘turn-key’ representation
           services in market for our clients. From travel trade lobbying
           and liaison, to tour operator and travel agency training,
           roadshows and sales missions, sales calls, advertising and
           promotions, exhibitions and events, product development and
           strategic counsel, we are the industry leaders when it comes
           to growing your visitor share in Europe.
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Creating connections that inspire dream vacations.</Card.Title>
           <Card.Text><p>From the friendly people to the unforgettable experiences,
           South Africa has so much to offer travelers — and the country
           wanted to attract more visitors from the United Kingdom and
           Ireland. They just needed to know how safe and welcoming South Africa
           actually is. So the country turned to Global Partners to help craft
           meaningful stories that make UK and Irish travelers feel connected
           to South Africa, and eager to book travel there.</p>

            <p>Global Partners planned a creative strategy around engaging content
            to showcase the friendly, welcoming nature of South Africa’s
            people and the beauty of its geography.</p></Card.Text>

           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Reach UK and Irish travelers with messaging and stories
        that compel them to plan visits to South Africa.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Travel & Tourism</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>Travel Marketing Awards: PR Strategic Campaign 2020 (2nd place)</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/South-Africa-Hero.jpg"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Title>Selecting local guides to showcase destinations</Card.Title>
        <Card.Text>First we selected 12 passionate “guides” from different
        provinces across the country, representing an array of tourism — to
        reveal the breadth of experiences available and encourage geographical
        spread. Our content created connections between UK and Irish travelers
        and South African people to inspire them to visit the destination.</Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Connecting faces with places</Card.Title>
        <Card.Text>Then, we pitched profile features on the guides in the national
        and travel press to share their local knowledge and expertise around
        South African Tourism’s key themes. In all, we secured nine media
        interviews in UK publications, including Wanderlust, Travel Weekly,
        the i Newspaper, Selling Travel, and Lancashire Life.</Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>Organizing immersive press trips</Card.Title>
        <Card.Text>
        <p>We also organized two dedicated Meet Your South Africa (MYSA)
        group press trips for UK and Irish media and incorporated the guides
        into all individual trip itineraries. We also arranged for the
        MYSA guides to appear at six UK events to engage with media and
        conduct interviews, including a London launch media event,
        cookery workshop, WTM, Destinations, and the Holiday World Show.
        </p>
        <p>As a result, the Meet Your South Africa guides were seen throughout
        the UK and Ireland. We generated 40 pieces of coverage reaching
        over 58,321,670, with a total PR value of £1,736,532. We hosted 10
        media on dedicated MYSA trips — representing the Irish Daily
        Mail, The Sun, Fabulous Magazine, OK Magazine, and Sunday Independent.
        </p>

        </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default TravelTradeRepresentation;
