import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Arts() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Communicating cultural excellence.</Card.Title>
         <Card.Text><p>Forming close partnerships and offering unmatched resources — that’s how Polskin Arts & Communications Counselors has earned a coveted place as a leading arts PR agency.</p>
          <p>We take great pride in providing communications and program implementation support for the world’s top fine arts organizations, in the U.S. and far beyond.</p>
          <p>As a division of Global Partners, Polskin Arts offers our clients advantages no other arts PR agency can — the resources of a global agency, an unparalleled network of professional relationships, and a depth of experience across disciplines that both encompass and transcend the arts.</p>
          </Card.Text>
           <Card.Img className="nyash" src={"/static/media/Arts-200.jpg"}/>
         <Card.Text>
           <p>That&apos;s how we’ve been able to build and maintain partnerships that often span decades. Our valued partners include:</p>
           <ul>
            <li>Museums</li>
            <li>Performing arts organizations and festivals</li>
            <li>Philanthropic foundations and corporate sponsors of the arts</li>
            <li>Galleries, art fairs, and auction houses</li>
            <li>Government agencies engaged in cultural initiatives</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Arts Brand Strategy</Accordion.Header>
       <Accordion.Body>
        A compelling message, clear market position, and engaging personality are keys to standing out from the competition — which is even harder in the fine arts world. We combine detailed research and vast experience to help build your arts brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Arts Content Marketing</Accordion.Header>
       <Accordion.Body>
        Driving traffic to your site takes relevant content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Arts Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Arts Public Relations</Accordion.Header>
       <Accordion.Body>
         In the world of the arts it helps greatly to have a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and growing your arts business or organization anywhere in the world.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>
    </Container>
  )
}

export default Arts;
