import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function FinancialServices() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Communicating the future of finance.</Card.Title>
         <Card.Text>
          <p>Once-in-a-generation changes are underway within the finance
          industry. Digitalisation is revolutionising delivery while sustainability
          and inclusivity are driving innovation for providers, whether they are
          market leaders or challengers. In this changing and challenging environment,
          telling your story – and telling it well – has never been more important.</p>
          <p>Global Partners is an unrivalled provider of thought-leadership campaigns
          offering multi-channel engagement across media relations, content development,
          content amplification, events, branding and crisis management. Specialist sectors
          include banking, insurance, asset management, sustainable finance, private equity
          and fintech. A longstanding partner to some of the largest global banks,
          Global Partners has an unrivalled understanding of the processes, regulations and
          sensitivities within this often-misunderstood industry.</p>
          <p>Since its inception in 2013, the Global Partners team has grown rapidly into one
          of the largest and most innovative financial services PR and marketing practices globally,
          drawing on a combination of strong organic growth, excellent hiring and clever acquisitions.
          Global Partners has the depth, breadth and vision to ensure our clients’ communications are
          innovative, targeted, appropriate and well-ahead of their rivals.</p>
          <p>Global.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/Windmills-200.jpg"}/>
         <Card.Text>
           <p><b>SECTOR EXPERTISE</b></p>
           <p>We consider our clients’ expertise their greatest asset. Our role is to articulate this
           expertise to the right audiences and in the appropriate format to achieve the desired impact.
           This approach informs all our strategies. Narrative and story-telling are therefore
           critical – typically directing us towards thought leadership campaigns involving strong content
           generation (in all forms), as well as effective distribution.</p>
           <p>A key differentiator is that Global Partners develops the content via our team of internal writers
           tasked with reflecting the core messaging and unique “voice” of our clients. We never outsource the
           writing – allowing our teams to acquire the intimate knowledge required for the partnership approach
           we favour. The result is compelling and tailored content that can be distributed through various channels,
           including the media.</p>
           <ul>
            <li>Whitepaper research, generation and design – including media launch;</li>
            <li>Proactive news generation campaigns based on triggers such as deals, product launches and appointments;</li>
            <li>Commentary articles bylined to key individuals and placed in the media (without payment);</li>
            <li>Events including roundtables, seminars and webinars, as well as industry event speaker-slot research and lobbying;</li>
            <li>Digital media production and marketing services, including website design, videos, podcasts, social media, as well as analytics and SEO optimisation</li>
            <li>Crisis communications services, including proactive messaging preparation and media training, and reactive media defence and rebuttal.</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Financial Advertising</Accordion.Header>
       <Accordion.Body>
         We have unmatched expertise when it comes to reaching audiences in the financial services field with memorable, effective messaging. We’ll use data-driven tactics to increase your brand awareness and hit your goals — thus maximizing the ROI of your ad efforts.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Financial Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
        The financial services field is a crowded one, and standing out from the competition takes a compelling message, clear market position, and engaging personality. We combine detailed research and vast experience to help build your brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Financial Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your financial services institution. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Financial Public Relations</Accordion.Header>
       <Accordion.Body>
         Financial services brands can benefit greatly by having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and growing your brand in financial markets around the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Financial Research & Insights</Accordion.Header>
       <Accordion.Body>
         The financial services landscape is constantly growing and evolving, and success requires research and insights your institution can rely on. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default FinancialServices;
