import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function SustainabilityAndESG() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Embracing sustainability for success in today’s market.</b></Card.Title>
            <Card.Text>For companies of all sizes and kinds, sustainability and
            ESG (environmental, social, and governance) are no longer optional. They’re
            absolute business imperatives. Not only do they mitigate risk, expand opportunities,
            and improve stakeholder engagement, consumers seek companies who commit to sustainability and ESG.
            </Card.Text>
            <Card.Text>
              Global Partners serves those companies as a sustainability PR and marketing agency, working closely
              with them to integrate ESG performance into their business and communications strategies.
            </Card.Text>
            <Card.Text>
              Pursuing and enhancing sustainability performance is a challenge for many companies. It takes a smart
              plan and a great partner. We help them determine the right strategy, engage stakeholders, and communicate
              complex information — on the way to reaching sustainability goals.
            </Card.Text>

           </Card.Body>
          </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Img className="nyash" src={"/static/media/Sustainability-ESG-200.jpg"}/>
            <Card.Text>
              Our range of capabilities as a sustainability communications agency includes:
            </Card.Text>
            <Card.Text>
              <ul>
                <li>ESG reporting and disclosure</li>
                <li>ESG investor communications</li>
                <li>Strategically engaging KOLs and influencers</li>
                <li>Building executive positioning and thought leadership platforms</li>
                <li>ESG research analytics</li>
              </ul>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Sustainability Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear mission, and engaging personality — those are the keys to raising awareness and building advantages in the areas of sustainability and ESG. We’ll help you do it, through a combination of detailed research and vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Sustainability Content Marketing</Accordion.Header>
       <Accordion.Body>
         Driving traffic to your site takes relevant content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Sustainability Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Sustainability Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your sustainability and ESG initiatives. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Sustainability Public Affairs</Accordion.Header>
       <Accordion.Body>
         Creating an impact and making progress in sustainability and ESG takes maintaining strong relationships with legislators and stakeholders. We can help you build those relationships — along with a health public affairs strategy that’s clear and effective.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Sustainability Public Relations</Accordion.Header>
       <Accordion.Body>
         Your sustainability and ESG efforts will greatly benefit from having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and enhancing your efforts anywhere in the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>Sustainability Research & Insights</Accordion.Header>
       <Accordion.Body>
         Things are quickly growing and constantly evolving in the areas of sustainability and ESG, and success requires research and insights you can rely on. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default SustainabilityAndESG;
