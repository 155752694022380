import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function ResearchInsights() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Deeply understanding what drives your audience.</Card.Title>
         <Card.Text><p>For you to connect with your key audiences and stakeholders,
         you have to understand them as people. What do they think? What do they want?
         What issues do they face? And what would compel them to take certain actions?</p>
          <p>Global Partners finds the answers — using breakthrough technology to conduct
          intensive research and mine data from innumerable sources, and deep expertise to
          translate the data to actionable insights.</p>
          <p>Global Partners&apos; Global Intelligence team works on an integrated, agency-wide
          basis with each practice group to assist with strategy, planning, and measurement.
          This empowers us to create results-driven marketing programs that are rooted in
          analytics and insights, and powered by strategy and creativity.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/Research-Insights-200.jpg"}/>
         <Card.Text>
           <p>Through Global Partners&apos; research and insights capabilities, brands
           and organisations gain a profound understanding of key audiences — allowing
           them to build trust, develop relationships, and inspire action. Our research
           and insights capabilities include:</p>
           <ul>
            <li>Data and analytics</li>
            <li>Quantitative and qualitative research</li>
            <li>Primary and secondary research</li>
            <li>Focus groups</li>
            <li>Audience data and research: insights and persona creation</li>
            <li>Audience demographics research and segmentation</li>
            <li>Empathy mapping</li>
            <li>Sentiment and share-of-voice analyses</li>
            <li>Competitor analysis</li>
            <li>Social listening</li>
            <li>Omnibus surveys: report data-gathering and creation</li>
            <li>Channel strategy</li>
            <li>Messaging insights</li>
            <li>Data-driven storytelling angles</li>
            <li>Trend identification and analysis</li>
            <li>Brand health and reputation analysis: brand values mapping</li>
            <li>Campaign and content performance measurement and analysis</li>
            <li>Influencer research and engagement strategy</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>

    </Container>
  )
}

export default ResearchInsights;
