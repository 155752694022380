import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function SEOStrategies() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Utilizing SEO strategies to grow your brand&apos;s visibility online.</Card.Title>
         <Card.Text><p>We understand what is required to deliver a successful
          and sustainable SEO campaign which will take you to the top of the
          organic search results.</p>
          <p>A carefully considered blend of engaging, relevant content,
          technical website expertise, and brand exposure through targeted
          outreach and digital PR campaigns.</p>
          <p>Improving your organic search rankings has no doubt become more
          complicated, but with the right SEO strategy, and the help of our
          team of SEO experts, we will be able to impact your website’s
          rankings drastically.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/SEO-strategies-200.jpg"}/>
         <Card.Text>
           <p>No SEO strategy is the same. To create a strategy that delivers results, we start with a conversation to get to know your needs and objectives.</p>
           <p>Our strategies are driven by performance.  Our goal is always to drive highly-targeted, relevant traffic to your website, which in turn, will lead to more conversions – be it enquiries or sales.</p>
           <p>All our search strategies are carefully planned, executed, and monitored to make sure the project stays on track and continues to grow organic traffic to your website.</p>
           <p>Whether your organic rankings and traffic are declining, you are planning a website migration, entering a new market or you simply need help meeting your organic traffic KPIs, the Global Partners SEO team can help.</p>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>

    </Container>
  )
}

export default SEOStrategies;
