import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function ConsumerLifestyle() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <p><Card.Title><b>Creating meaningful brand connections.</b></Card.Title>
         <Card.Text>
         Across the vast consumer landscape, established companies, emerging innovators and category leaders entrust Global Partners to tell their stories and transform their brands. Our expertise spans consumer brands and clicks and bricks retail across, lifestyle, home improvement, food and beverage, fashion, leisure, wellness and luxury. From launching new products to activating “marketing moments,” we create powerful campaigns and cultural movements that build brands.
         </Card.Text>
         </p>
         <p><Card.Title>Consumer Team</Card.Title>
         <Card.Text>
         Our consumer specialists have diverse backgrounds in integrated marketing, blending PR, earned media, social media, events, and the best marketing acumen to help consumer and retail brands deliver successful 360-degree results. We create integrated digital, social and influencer campaigns, secure global, national and regional coverage and implement successful large-scale events, press previews, seasonal campaigns and new product launches. We help clients implement new strategies that set themselves apart with any audience and through any channel.
         </Card.Text>
         </p>
         <p><Card.Title>Expertise</Card.Title>
         <Card.Text>
          For two decades the Global Partners consumer team in Europe, formerly ZPR have worked with brands and retailers evoking emotional connections that motivate consumers to align their everyday lives with our brand partners.
         </Card.Text>
         <Card.Text>
          Challenging the beauty industry on diversity, lobbying Government to remove VAT on essential consumer items, shining a light on ageism in the fashion industry, recommending authentic celebrity talent or influencer ambassador campaigns to front brands or launches are just a few of our success stories.
         </Card.Text>
         </p>
            <Card.Img className="nyash" src={"/static/media/Consumer-Brand-200.jpg"}/>
         <Card.Text>
           <p>We use strategic storytelling and impactful news ideas — the kind that resonate deeply with today’s savvy buyers — to elevate brands and empower them to leave a lasting impression.</p>
           <p>Our experience as an agency runs deep, with capabilities and expertise across countless industries. These include:</p>
           <ul>
            <li>Beauty</li>
            <li>E-commerce</li>
            <li>Fashion</li>
            <li>Fitness</li>
            <li>Food and beverage</li>
            <li>Home improvement and DIY</li>
            <li>Luxury</li>
            <li>Lifestyle</li>
            <li>Retail</li>
            <li>Restaurants and bars</li>
            <li>Sports betting and entertainment</li>
            <li>Wellness</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">
        <Card.Body>
         <Card.Title>Surprise And Delight Press & Influencers With Spook-Tacular Gifts</Card.Title>
         <Card.Text>
          To launch Hobbycraft’s spook-tacular Autumn and Halloween product range, the Global Partners team were challenged with encouraging consumers to “Get their home autumn ready”, by showcasing the new collection in an impactful way whilst positioning Hobbycraft as the go-to retailer for DIY and ready-made products, drive awareness, sales, press call-ins and social media buzz.
         </Card.Text>
        </Card.Body>
        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Encouraging consumers to “Get their home autumn ready”, by showcasing the new Hobbycraft collection in an impactful way whilst positioning Hobbycraft as the go-to retailer for DIY and ready-made products.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Consumer</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Untitled-design-202.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
         <Card.Text>
          <p>Surprise and delight 50+ top-tier national, consumer, lifestyle media and influencers with a WOW unboxing moment. Each hamper was personalised with the recipient’s name and filled with plush pumpkins and other home décor items for people to create an “Instagrammable” moment within their homes. To drive people to the website, each person was given two gift vouchers and a personalised compliment slip.</p>
          <p>Titles of the media included <b>Daily Mail, The Sunday Times, ITV This Morning, The Guardian, Metro, The Telegraph, Independent, Ideal Home, Grazia, House Beautiful, Prima, Mother & Baby</b> and many more.</p>
          </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Consumer Strategy & Development</Accordion.Header>
       <Accordion.Body>
         The consumer brands field is a crowded one, and standing out from the competition takes a compelling message, clear market position, and engaging personality. We combine detailed research and vast experience to help build your brand. We develop campaigns with a social purpose and respect for our planet and future generations.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Consumer Content Marketing</Accordion.Header>
       <Accordion.Body>
        Driving traffic to your site takes relevant content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Consumer Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your consumer brand. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Consumer Influencer Marketing</Accordion.Header>
       <Accordion.Body>
         Influencer marketing can be highly effective, but it takes finding the influencer whose personality and style are the perfect fit for your consumer brand. We have close relationships with numerous influencers who can accelerate your growth.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Consumer Public Relations</Accordion.Header>
       <Accordion.Body>
         Consumer brands can benefit greatly by having strong, genuine, lasting relationships with the media. We’ll help you build those relationships, nurturing new networks and growing your brand in target markets around the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Consumer Research & Insights</Accordion.Header>
       <Accordion.Body>
         The consumer brand landscape is constantly growing and evolving, and your brand needs research and insights you can rely on. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default ConsumerLifestyle;
