import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SolutionsLayout from './containers/SolutionsLayout';
import AppLayout from './containers/AppLayout';
import AboutUs from './containers/AboutUs';
import TravelTradeRepresentation from './subs/TravelTradeRepresentation';
import Advertising from './subs/Advertising';
import BrandStrategyDevelopment from './subs/BrandStrategyDevelopment';
import ContentMarketing from './subs/ContentMarketing';
import CorporateCommunications from './subs/CorporateCommunications';
import DigitalMarketing from './subs/DigitalMarketing';
import InfluencerMarketing from './subs/InfluencerMarketing';
import PaidMedia from './subs/PaidMedia';
import PublicAffairs from './subs/PublicAffairs';
import PublicRelations from './subs/PublicRelations';
import ResearchInsights from './subs/ResearchInsights';
import SEOStrategies from './subs/SEOStrategies';
import Arts from './works/Arts';
import Associations from './works/Associations';
import ConsumerLifestyle from './works/ConsumerLifestyle';
import CSR from './works/CSR';
import Education from './works/Education';
import FinancialServices from './works/FinancialServices';
import Health from './works/Health';
import Manufacturing from './works/Manufacturing';
import ProfessionalServices from './works/ProfessionalServices';
import SupplyChainLogistics from './works/SupplyChainLogistics';
import SustainabilityAndESG from './works/SustainabilityAndESG';
import Technology from './works/Technology';
import Wellness from './works/Wellness';
import TravelTourism from './works/TravelTourism';
//import ConsumerLifestyle from './works/ConsumerLifestyle';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" name="home" element={<AppLayout/>} />
        <Route path="/solutions" name="solutions" element={<SolutionsLayout/>} />
        <Route path="/travel_trade_representation" name="travel_trade_representation" element={<TravelTradeRepresentation/>} />
        <Route path="/advertising" name="advertising" element={<Advertising/>} />
        <Route path="/brand-strategy-development" name="brand-strategy-development" element={<BrandStrategyDevelopment/>} />
        <Route path="/content-marketing" name="content-marketing" element={<ContentMarketing/>} />
        <Route path="/corporate-communications" name="corporate-communications" element={<CorporateCommunications/>} />
        <Route path="/digital-marketing" name="digital-marketing" element={<DigitalMarketing/>} />
        <Route path="/influencer-marketing" name="influencer-marketing" element={<InfluencerMarketing/>} />
        <Route path="/paid-media" name="paid-media" element={<PaidMedia/>} />
        <Route path="/public-affairs" name="public-affairs" element={<PublicAffairs/>} />
        <Route path="/public-relations" name="public-relations" element={<PublicRelations/>} />
        <Route path="/research-insights" name="research-insights" element={<ResearchInsights/>} />
        <Route path="/seo-strategies" name="seo-strategies" element={<SEOStrategies/>} />
        <Route path="/arts" name="arts" element={<Arts/>} />
        <Route path="/associations" name="associations" element={<Associations/>} />
        <Route path="/consumer-lifestyle" name="consumer-lifestyle" element={<ConsumerLifestyle/>} />
        <Route path="/csr" name="csr" element={<CSR/>} />
        <Route path="/education" name="education" element={<Education/>} />
        <Route path="/financial-services" name="financial-services" element={<FinancialServices/>} />
        <Route path="/health" name="health" element={<Health/>} />
        <Route path="/manufacturing" name="manufacturing" element={<Manufacturing/>} />
        <Route path="/professional-services" name="professional-services" element={<ProfessionalServices/>} />
        <Route path="/supply-chain-logistics" name="supply-chain-logistics" element={<SupplyChainLogistics/>} />
        <Route path="/sustainability-and-esg" name="sustainability-and-esg" element={<SustainabilityAndESG/>} />
        <Route path="/technology" name="technology" element={<Technology/>} />
        <Route path="/wellness" name="wellness" element={<Wellness/>} />
        <Route path="/travel-tourism" name="travel-tourism" element={<TravelTourism/>} />
        <Route path="/consumer-lifestyle" name="consumer-lifestyle" element={<ConsumerLifestyle/>} />
        <Route path="/about-us" name="about-us" element={<AboutUs/>} />
      </Routes>
    </BrowserRouter>
  );
}
