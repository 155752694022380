import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function InfluencerMarketing() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Engaging the right people to tell your story the right way.</Card.Title>
         <Card.Text><p>Expertly crafted content, authentic storytelling, 
         and a knack for capturing and persuading digital followers — that’s
         what great influencers do. We know the right ones (or we’ll find
           them) to excite and engage your target audience.</p>
          <p>Global Partners develops unique influencer strategies that are designed to meet business goals and deliver true ROI.</p>
          <p>When developing programmes for our clients, we consider cultural,
          media, and reputational influencers, and hand-select them based on
          resonance, authenticity, audience, and reach via their content.</p></Card.Text>
            <Card.Img className="nyash" src={"/static/media/Influencer-Marketing-20.jpg"}/>
         <Card.Text>
           <p>Our in-house team of experts works with leading influencers to
           create highly effective custom solutions — whether it’s gifted
           activity, experiential activations, branded content, and social
           media campaigns.</p>
           <p>Our reach and expertise span a wide range of industries, including:</p>
           <ul>
            <li>Fashion</li>
            <li>Beauty</li>
            <li>Health and wellness</li>
            <li>Travel</li>
            <li>Technology</li>
            <li>Lifestyle</li>
            <li>Education</li>
            <li>Food and beverage</li>
            <li>Wine and spirits</li>
            <li>Advertising</li>
            <li>CSR</li>
            <li>Sports</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>5-A-Side and Fanzone.</Card.Title>
           <Card.Text>To support Ladbrokes 5-A-Side and Fanzone propositions throughout
           the Premier League, the Euros and the World Cup we staged interviews and
           social media content with former high-profile players.
           </Card.Text>
           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>To raise the profile of Ladbrokes 5-A-Side and Fanzone propositions over the course of two years.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Consumer – Lifestyle</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/5-A-Side-Ladbrokes-10.jpg"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Title><b>Compelling brand story.</b></Card.Title>
        <Card.Text>
         <p>We secured and staged interviews and social media content with
         former international players and famous ex-E manager including Sven-Goran
         Erikson, Sol Campbell, Hernan Crespo, William Gallas, Eduardo, Emmanuel
         Petit and Frank Lebouef</p>
         <p>This continues to be an everyday part of our PR plans for Ladbrokes
         and for the 22/23 season. In total the approach achieved almost 1,000
         pieces of national coverage as part of interview activity, with every
         ex-pro averaging around 15 pieces of coverage a week.</p>
         </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default InfluencerMarketing;
