import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

  function SolutionsLayout() {
   return (
      <Container>

      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/-young-_0KJ4A.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title><b>Arts</b></Card.Title>
         <Card.Text>Communicating cultural excellence.
          Forming close partnerships and offering unmatched resources — that’s how Polskin Arts & Communications Counselors has earned a coveted place as a leading arts PR agency.</Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/arts">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/mybsi-hero.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>Associations</b></Card.Title>
           <Card.Text>Building brand strength and growing membership.
            As a leading nonprofit marketing agency, Global Partners&apos; team has a decades-long history of giving associations and nonprofits everything they need— endless support to create and sustain their brands,</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/associations">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Consumer-Brand-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title>Consumer & Lifestyle</Card.Title>
           <Card.Text>Creating meaningful brand connections.
            Across the vast consumer landscape, established companies,
            emerging innovators and category leaders entrust Global Partners
            to tell their stories and transform their brands. Our expertise
            spans consumer brands </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/consumer-lifestyle">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/CSR-Social-Impact-200.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title>Corporate Social Responsibility</Card.Title>
         <Card.Text>
          Building awareness is an important first step in achieving corporate social responsibility (CSR) and social impact. But awareness alone doesn’t create change — that takes commitment and action.
          From corporations to nonprofits, Global Partners helps
         </Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/csr">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Education-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Education</b></u></Card.Title>
           <Card.Text><b>Changing the world, one student at a time.</b> Our clients are educators, experts, leaders, and innovators.
           They re-imagine education, design learning platforms and solutions, and lead
           great colleges and universities. They are advocates at global </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/education">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Windmills-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Financial Services</b></u></Card.Title>
           <Card.Text><b>Communicating the future of finance.</b> Once-in-a-generation changes are underway within the finance
           industry. Digitalisation is revolutionising delivery while sustainability
           and inclusivity are driving innovation for providers, whether they are
           market leaders </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/financial-services">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/Health-200.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title className="center_scene"><u><b>Health</b></u></Card.Title>
         <Card.Text><b>Quickening the pace and pulse of health innovation.</b>. The pace of health innovation never stops. From new pharmaceutical
         advances to how hospital services are accessed, a dramatic and long-anticipated
         shift in healthcare is happening — and </Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/health">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Bosch-hero-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Manufacturing</b></u></Card.Title>
           <Card.Text><b>Launching a new product into the digital world.</b>. With a new product to launch in a crowded marketplace, Bosch Packaging Technology
           (now Syntegon) needed to create brand awareness, generate leads online and increase market share
           globally </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/manufacturing">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Professional-Services-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Professional Services</b></u></Card.Title>
           <Card.Text><b>Get seen and chosen, even in a crowded field.</b>. To stand out and stay successful, professional services leaders have to
           find new ways to connect their brands with opportunities and to demonstrate their value.
           It seems straightforward, but it’s a </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
            <Nav.Link href="/professional-services">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/Supply-Chain-200.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title className="center_scene"><u><b>Supply Chain & Logistics</b></u></Card.Title>
         <Card.Text><b>Making critical connections count.</b>. Supply chain and
         logistics are more complex and important than ever. They can create a
         competitive advantage, raise brand perception, and impact overall business
         performance</Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
          <Nav.Link href="/supply-chain-logistics">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Sustainability-ESG-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Sustainability & ESG</b></u></Card.Title>
           <Card.Text><b>Embracing sustainability for success in today’s market.</b> For companies of all sizes and kinds, sustainability and
           ESG (environmental, social, and governance) are no longer optional. </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
            <Nav.Link href="/sustainability-and-esg">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Tech-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title className="center_scene"><u><b>Technology</b></u></Card.Title>
           <Card.Text><b>High-touch communications for a high-tech world.</b>. Technology has fundamentally changed the way we engage with the world
           and each other — both in business and in life. That’s why every organisation</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
            <Nav.Link href="/technology">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">
        <Card.Body>
         <Card.Title>Wellness</Card.Title>
         <Card.Text><b>Taking care of what’s important.</b> Wellness is no longer a buzzword or a trend. It’s a way of
         life that inspires consumers by the millions — and Global Partners is
         committed to helping wellness efforts succeed for our clients and communities,
         by serving as a health and wellness marketing agency resource.</Card.Text>
         <div className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/wellness">Go There</Nav.Link>
         </Button>
         </div>
         </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Travel & Tourism</Card.Title>
           <Card.Text><b>Destined to deliver great experiences.</b>. Unforgettable experiences, wonderful memories, and once-in-lifetime
           adventures — that’s what travellers seek, and it’s what travel brands and
           destinations deliver. As a renowned travel PR and marketing agency, Global Partners
           makes meaningful connections</Card.Text>
           <div className="FirstPageCard">
           <Button variant="primary">
            <Nav.Link href="/travel-tourism">Go There</Nav.Link>
           </Button>
           </div>
           </Card.Body>
        </Card>

        </Col>
      </Row>
       </Container>
     );
   }

export default SolutionsLayout;
