import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function MainMenu() {
  return (

      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/">Home</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/about-us">About</Nav.Link>
            <Nav.Link href="/solutions">Works</Nav.Link>
            <Nav.Link href="/contact">Products</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

  );
}

export default MainMenu;
