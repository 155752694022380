import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Education() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title><b>Changing the world, one student at a time.</b></Card.Title>
         <Card.Text><p>Our clients are educators, experts, leaders, and innovators.
         They re-imagine education, design learning platforms and solutions, and lead
         great colleges and universities. They are advocates at global NGOs and community-based
         organisations, members of foundations and associations, and visionaries of the private sector.</p>
          <p>They’re changing the world through tireless work in every aspect of the education ecosystem,
          and Global Partners gives them the full services and support of an all-round education marketing
          agency — to tell their story with power and purpose.e</p>
          <p>Backed by 30 years of experience, Global Partners knows the education landscape well. Our team
          understands the unique needs of teachers, faculty, staff, parents, and students, as well as the
          critical issues affecting the sector — such as affordability, racial and social equity, access
          to resources and technology, and the ever-increasing pressures of funding challenges
          and policy changes.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/Education-200.jpg"}/>
         <Card.Text>
           <p>That knowledge and experience benefit each Global Partners client. We serve as their education
           marketing agency and help define their brands, reach the right audience, and articulate
           their mission.</p>
           <p>
            Then we empower them to meet lofty communication goals through thoughtful integrated
            campaigns that use the latest digital tools and data, and the most nuanced storytelling.
            And when the unexpected happens, we’re right there to guide our clients through crises.
           </p>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Education Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear mission, and engaging personality — those
         are the keys to raising awareness and building advantages for education
         entities and institutions. We’ll help you do it, through a combination of
         detailed research and vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Education Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to
         your brand values. We build communication strategies that strike that tone — and,
         if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Education Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a
         digital marketing plan customized to your education institution and brand. Our
         integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Education Public Relations</Accordion.Header>
       <Accordion.Body>
         It helps greatly to have a strong, genuine, lasting relationship with the media.
         We’ll help you build that relationship, nurturing new networks and growing your
         education business or institution at a grassroots level, locally or around the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Education Research & Insights</Accordion.Header>
       <Accordion.Body>
         The education space is constantly evolving, and your business or institution needs
         research and insights you can rely on. We have the expertise and resources to conduct
         market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default Education;
