import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function ContentMarketing() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Creating optimised content that delivers.</Card.Title>
         <Card.Text><p>It’s no big secret: Content is king. Content fuels
         the digital marketing tactics that increase customer engagement,
         loyalty that drives business results and enables you to stand out
         from the crowd.</p>
          <p>In the age of disruptive advertising and content spam, we help
          you reduce the noise by designing, planning and executing bespoke
          branded content and strategies.</p>
          <p>At Global Partners, our team of experts help you to reduce the
          noise in an age of disruptive advertising and content spam, by
          designing, planning and executing bespoke branded content and
          strategies.</p></Card.Text>
            <Card.Img className="nyash" src={"/static/media/Content-Marketing-21191.jpg"}/>
         <Card.Text>
           <p>Global Partners&apos; content marketing strategists and SEO experts
           help our clients amplify brand visibility, become a leading industry
           voice, and achieve greater business success. Our wide array of
           capabilities include:</p>
           <ul>
            <li>Content and SEO audits and strategy</li>
            <li>Content production for SEO</li>
            <li>Backlink building</li>
            <li>Editorial and thought leadership content creation:
            bylines, op-eds, case studies, blog posts, white papers,
            press releases</li>
            <li>Sales and marketing collateral: ebooks, decks, talk tracks, sales sheets</li>
            <li>Multimedia and interactive content: concept and production</li>
            <li>Design: graphic, motion, visual, presentations, infographics</li>
            <li>Landing page and website content and design</li>
            <li>Company blog: strategy, editorial calendar, content creation</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Launching a new product into the digital world.</Card.Title>
           <Card.Text>With a new product to launch in a crowded marketplace,
           Bosch Packaging Technology (now Syntegon) needed to create brand
           awareness, generate leads online and increase market share globally
           outside of traditional trade shows. To facilitate the successful
           launch of the world’s fastest vertical bagger with Doy Zip functionality,
           Global Partners masterminded and executed an integrated global marketing
           campaign to target and connect with key decision-makers and ultimately
           drive sales.
           </Card.Text>
           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Facilitate the successful launch of the latest Bosch packaging machine, generating global sales and increasing market share.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Manufacturing</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/Bosch-goal-10.jpg"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Title><b>Compelling brand story.</b></Card.Title>
        <Card.Text>
         <p>Global Partners helped Bosch Packaging Technology establish a differentiated messaging platform to position Doy Zip from Bosch as the world fastest vertical bagger that also offers a premium look, helping brands to attract consumers at the point-of-sale. The new brand story strongly distinguished Doy Zip technology from competitors, capturing the attention and interest of prospects.</p>
         <p><b>Robust sales approach that lasts</b></p>
         <p>Multi-lingual, multi-channel sales and marketing campaign not only generated strong interest among target audiences, but also drove visitors to the Doy Zip microsite – the heart of the campaign and sales generation machine. With over 400 global leads generated via the microsite in a 12-month period, Global Partners created a well-oiled lead generation tool that will deliver for years to come.</p>
         <p><b>Outside-the-box thinking</b></p>
         <p>Engineer-focused, Global Partners challenged the traditional sales approach at Bosch Packaging Technology via extensive training of sales teams to target both decision-making groups – engineers and marketers. Global Partners sales experts advised on how to best leverage the powerful positioning and messaging platform to gain traction with key decision-makers with an outside-the-box sales presentation template.</p>
         <p><b>Spreading the word</b></p>
         <p>A well-focused, integrated communications campaign was launched to boost interest and leads. Multi-faceted public relations, thought leadership activities and PPC campaign in English, German, Spanish, French and Chinese drove traffic to the microsite with over 20,000 visits in the first year and captured the market’s interest.</p>
         <p>The digital possibilities of launching and promoting a product are limitless. Partnering with an experienced team will allow you to take shortcuts to effective B2B product launch strategies and equip your sales teams with a continuous flow of high-quality leads.</p> </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default ContentMarketing;
