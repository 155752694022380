import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function MainFooter() {
  return (
    <Row>
      <Col sm className="main_scene2">


      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/sunset-in-dawn-4332.jpg"} />
        <Card.Body>
         <Card.Title className="main_scene2">Get your marketing edge.</Card.Title>
         <Card.Text><b>The best relationships start with a conversation. Get in touch and you’ll have the force of Gravity in play before you know it.</b></Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
          Lets Talk
         </Button>
         </Card.Footer>
      </Card>

      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
        <Navbar.Brand href="/">Home</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/about-us">About</Nav.Link>
          <Nav.Link href="/solutions">Works</Nav.Link>
          <Nav.Link href="/contact">Products</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      </Col>
    </Row>
  );
}

export default MainFooter;
