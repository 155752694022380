import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function CSR() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/CSR-Social-Impact-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Empowering those who make meaningful change.</b></Card.Title>
            <Card.Text><p>Building awareness is an important first step in achieving
            corporate social responsibility (CSR) and social impact. But awareness alone
            doesn’t create change — that takes commitment and action.</p>
             <p>From corporations to nonprofits, Global Partners helps organisations of
             all kinds achieve impact and transformation by inspiring their audiences.
             How? We create corporate social responsibility communications that are
             effective, action-oriented, and always compelling.</p>
             <p>When you partner with Global Partners, your cause becomes our cause.</p>
            <p></p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
          <p>Global Partners helps clients create results-generating campaigns
          around complex social issues, such as sustainability, climate action
          and equality. Our CSR and social impact communications experts know
          what it takes to accomplish crucial goals. Those include:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>Strengthening brand reputation</li>
           <li>Supporting marketing goals</li>
           <li>Elevating initiatives</li>
           <li>Communicating purpose</li>
           <li>Shifting public thinking</li>
           <li>Driving immediate action</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Bahia-Hondita-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Making clean drinking water the pure focus.</b></Card.Title>
          <Card.Text><p>By turning water into air using Hydropanel technology,
          SOURCE Global, PBC is able to bring clean, potable water to communities
          around the world and in the US. And with America’s crumbling water
          infrastructure (and a new Presidential administration beginning), SOURCE
          saw a huge opportunity to raise awareness of infrastructure and sustainability
          for Americans in 2021 and into the future. It turned to Global Partners to help
          start and steer the conversation.</p>
           <p>Global Partners made a plan to spotlight all the ways SOURCE was solving
           water infrastructure issues and to build trust in communities most heavily
           impacted by water-related issues — mostly low-income and minority groups.</p>
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Establish SOURCE as a leading company bringing clean, potable water to indigenous and remote communities around the world.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>CSR & Social Impact</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>Fast Company: World Changing Idea</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/SOURCE-goal-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           <p><b>Discovering a crisis of trust</b></p>
           <p>In the spring of 2021, we worked with SOURCE to develop a survey on what diverse Americans thought about their tap water. It revealed the deep distrust among Black and Hispanic Americans related to the quality and safety of their water and the urgent need to overhaul our water system for those communities.</p>
           </Card.Text>
           <Card.Text>
            <p><b>Building credibility with the community</b></p>
            <p>To address and repair that trust, we built a relationship with Reverend Leo Woodbury from Florence, South Carolina, an activist focused on the disparity in water quality in underserved communities, including his own. The survey release coincided with the groundbreaking of Rev. Woodbury’s SOURCE Hydropanel installation.</p>
            </Card.Text>
            <Card.Text>
             <p><b>Leading the charge toward renewable water</b></p>
             <p>We also launched a media campaign that secured more than a dozen top-tier placements. This included multiple stories in The Hill, Forbes, NBC News, and many others. In total, Global Partners and SOURCE’s partnership generated more than a billion impressions worldwide, including features in CNN, BBC, Bloomberg and many other top-tier outlets.</p>
             <p>The work reached the world and elevated CEO and founder Cody Friesen as the leader in renewable, sustainable water.
             SOURCE was included in Fast Company’s World Changing Ideas and Cody’s byline,
             <a href="https://www.barrons.com/articles/americas-water-infrastructure-is-broken-why-the-biden-plan-wont-fix-it-51617285044">America’s Water Infrastructure Is Broken.
             Why the Biden Plan Won’t Fix It, was featured in Barron’s.</a></p>
             </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
       <Card.Body>
         <Card.Text>
          Whether you’re a business, corporation, startup, association, government agency, or nonprofit, Global Partners focuses on driving change and while meeting your objectives. To achieve that we leverage research, identify trends, solidify partnerships, and harness traditional and social media.
         </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>CSR Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear mission, and engaging personality — those are the keys to raising awareness and building advantages in the areas of CSR and social impact. We’ll help you do it, through a combination of detailed research and vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>CSR Content Marketing</Accordion.Header>
       <Accordion.Body>
        Driving traffic to your site takes long-form content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>CSR Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build CSR communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>CSR Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your CSR and social impact initiatives. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>CSR Influencer Marketing</Accordion.Header>
       <Accordion.Body>
         Influencer marketing can be highly effective, but it takes finding the influencer whose personality and style are the perfect fit for your CSR and social impact efforts. We have close relationships with numerous influencers who can help you hit your goals.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>CSR Public Relations</Accordion.Header>
       <Accordion.Body>
         Your CSR and social impact efforts will greatly benefit from having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and enhancing your efforts anywhere in the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>CSR Research & Insights</Accordion.Header>
       <Accordion.Body>
         Things are quickly growing and constantly evolving in the areas of CSR and social impact, and success requires research and insights you can rely on. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default CSR;
