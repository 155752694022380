import React from 'react';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Router from './router';
import MainMenu from './common/MainMenu';
import MainFooter from './common/MainFooter';

const App = () => (
  <ThemeProvider >
    <MainMenu/>
    <Router />
    <MainFooter />
  </ThemeProvider>
);

//const wrapper = document.getElementById("app");
//rapper ? createRoot(<App />, wrapper) : null;

export default App;
