import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function SupplyChainLogistics() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/Supply-Chain-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Making critical connections count.</b></Card.Title>
            <Card.Text><p>Supply chain and logistics are more complex and important
            than ever. They can create a competitive advantage, raise brand perception,
            and impact overall business performance — and they’re connected to pressing
            global issues, such as fair working practices and environmental responsibility.</p>
             <p>That means organisations in the industry must juggle competing priorities while
             solving old and new challenges. Global Partners guides them through it.</p>
             <p>We’re the supply chain PR agency that brings decades of experience and unmatched
             domain knowledge to each of our clients, who range from the supply chain organisations
             of Fortune 50 brands to leaders, challengers and start-ups in the technology and service
             provider community.</p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
            Our metrics-driven integrated communication programmes reach audiences across all channels and bring sophisticated capabilities to content generation, content marketing, SEO, SEM/PPC, social media, and PR.
           </Card.Text>
           <Card.Text>
           We have long-term relationships with targeted media across vertical, trade, B2B, technology, and business press — connections and credibility that we use to our clients&apos; advantage.
           </Card.Text>
           <Card.Text>
          <p>Global Partners serves as a true strategic partner, working with organisations
          throughout the global commerce ecosystem, including:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>Planning</li>
           <li>Sourcing</li>
           <li>Manufacturing</li>
           <li>Fulfillment</li>
           <li>Global logistics</li>
           <li>Transport</li>
           <li>Retail</li>
           <li>e-commerce</li>
           <li>Last-mile delivery</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/GT-Nexus-hero-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Amplifying the visibility of a supply chain visionary.</b></Card.Title>
          <Card.Text><p>GT Nexus was a global provider of supply chain visibility software with a solid
          brand reputation among supply chain decision makers. But the company wanted to elevate its
          profile with the C-suite — by focusing on how it transforms supply chains through greater
          visibility, collaboration, and agile orchestration. Global Partners came in to reshape GT Nexus&apos;
          value prop and reach the right audiences through activations in the US, UK, France, and Germany.</p>
           <p>Global Partners established a plan to target the C-suite and continue to speak to supply chain decision makers,
           with messaging about the benefits of moving their supply chain to the cloud.</p>
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Help GT Nexus seamlessly move its $3 trillion global supply chain to the cloud and reach the C-suite with a broader message and expanded value proposition.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Supply Chain & Logistics</p>
        <Card.Title>ACCOLADES</Card.Title>
         <p>PRSA Skyline Award</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/GT-Nexus-goal-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2"><b>Our Work At GT NEXUS</b></Card.Title>
       <Card.Body>

          <Card.Text>
            <p><b>Amplifying the visibility of a supply chain visionary.</b></p>
            <p>We created new messaging and positioning for GT Nexus that was smart, strategic, and compelling. It articulated the reasons to move past the inflexible old notion of supply chain to the dynamic new supply network, where everyone was connected and had access to all the information relevant to them.</p>
           </Card.Text>
           <Card.Text>
             <p><b>Creating drama out of disruption</b></p>
             <p>We also added some drama — creating demonstrations of the financial impact of major supply chain disruptions, such as natural disasters, and crafting messaging designed to poke some of the world’s largest tech companies.</p>
            </Card.Text>
            <Card.Text>
             <p><b>Activating the C-suite</b></p>
             <p>Further, there was a C-suite education program focused on the tremendous bottom-line impact of supply chain operations and a customer reference program that cultivated and activated marquee customers in media and across channels.</p>
             </Card.Text>
             <Card.Text>
              <p><b>Thought leadership newsjacking</b></p>
              <p>Finally, we hijacked news cycles to deliver thought leadership and secured 700+ placements across four markets. This included coverage in The Financial Times, The Times, FAZ, Suddeutsche Zeitung, Les Echos, Le Monde, Wall Street Journal, Fortune, Forbes, Bloomberg TV, Fox Business, CIO, Retail Week, and The Manufacturer.</p>
             </Card.Text>
             <Card.Text>
              <p><b>Fueling sales leads and a lucrative exit</b></p>
              <p>The new GT Nexus value proposition hit the mark, increasing Board- and C-suite-driven sales leads from some of the world’s leading companies. And eventually GT Nexus was acquired by Infor, a multi-billion-dollar enterprise technology leader, for $675 million.</p>
             </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Supply Chain & Logistics Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         To stand out from your competition in the supply chain and logistics field, you have to deliver a
         compelling message, clear market position, and engaging personality. We use detailed research and
         vast experience to help your company build its brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Supply Chain & Logistics Content Marketing</Accordion.Header>
       <Accordion.Body>
         Driving traffic to your site takes relevant content that’s high quality. It has to be interesting,
         insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Supply Chain & Logistics Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values.
         We build communication strategies that strike that tone — and, if needed, we craft honest
         responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Supply Chain & Logistics Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital
         marketing plan customized to your supply chain or logistics business. Our integrated approach
         will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Supply Chain & Logistics Public Relations</Accordion.Header>
       <Accordion.Body>
         Businesses focused on supply chain and logistics can benefit greatly by having a strong, genuine,
         lasting relationship with the media. We’ll help you build that relationship, nurturing new networks
         and growing your brand in key markets.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Supply Chain & Logistics Research & Insights</Accordion.Header>
       <Accordion.Body>
         The supply chain and logistics spaces are exciting and ever-evolving, and success takes research and
         insights your business can rely on. We have the expertise and resources to conduct market research and
         deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>

     </Accordion>

    </Container>
  )
}

export default SupplyChainLogistics;
