import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function AboutUs() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Purpose-built for our time.</b></Card.Title>
            <Card.Text>Global Partners is more than an integrated marketing agency.
            We are smart, passionate communications advocates on a mission to make a
            difference in the world. We change the way brands relate to their markets.
            The way companies are positioned and perceived. The way causes become movements.
            How stakeholders view stakes. How great stories are told and shared to earn
            attention. Where leads are generated and results tracked. We’ve even changed
            the way people within an agency collaborate and pull together for the greater
            good. Our work reflects our values. What we do is how we do things. Whether
            inside our walls or on the front lines of business transformation and social
            change, we stand together to create meaningful and measurable impact on the
            world around us.</Card.Text>

          </Card.Body>
          </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Img className="nyash" src={"/static/media/About-Us-200.jpg"}/>
            </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Leadership-1.jpg"}/>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
            <Card.Title>Leadership for a rapidly changing world</Card.Title>
          <Card.Text>
            Global Partners has an unrivaled bench of industry leaders with deep
            experience building brands and championing change across countless sectors
            and services. Our business model gives clients senior-level oversight on
            every account and access to practitioners who understand their business
            through decades of experience in their industry.
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/image-44-1.png"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Global reach</b></Card.Title>
          <Card.Text>
            We cover the globe. We have boots on the ground in key cities, and also leverage an extensive network of affiliates and partners in local markets around the world.
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

     </Row>

     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2"><b>Values We Live By</b></Card.Title>
      <Card.Body>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Work Hard. Play Nice.</Accordion.Header>
       <Accordion.Body>
         We’re nice people, and we aim to surround ourselves with the same. We know how to roll up our sleeves and get the job done, but we won’t sacrifice our integrity and values in the process.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Create A Best Place To Work</Accordion.Header>
       <Accordion.Body>
         It’s enough for some agencies to be a great place to work, but why stop there? We aim to be the best. We make our employees excited to show up each day, and we give them the resources they need to be awesome.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Take Risks</Accordion.Header>
       <Accordion.Body>
         With the entrepreneurial spirit and drive of a startup, we’re not afraid to take risks, challenge the status quo, and take the unconventional route to reach the end goal.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Amaze</Accordion.Header>
       <Accordion.Body>
         Whether it’s our clients, our employees, or our industry peers, we don’t stop at “surprise and delight.” We shoot for “amaze.” And we hear we’re hitting the target.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Make A Difference In The World</Accordion.Header>
       <Accordion.Body>
         Talk minus action equals … nothing. We work to improve our communities — and those of our clients — through purpose-driven actions. And we have the results to prove it.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Collaborate</Accordion.Header>
       <Accordion.Body>
         We’re a global agency, but no Global Partners office is an island. We’re pros at collaboration, and we know what it takes to nail our goals — no matter where in the world we are.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>Commitment To Diversity</Accordion.Header>
       <Accordion.Body>
         We’ve worked to promote diversity and inclusion at all levels since the day we opened our doors. It’s not only the right thing to do. It also makes Global Partners a better place — for all of our employees and our clients.
       </Accordion.Body>
      </Accordion.Item>

     </Accordion>
     </Card.Body>
  </Card>

  </Col>
  </Row>

    </Container>
  )
}

export default AboutUs;
