import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function PublicAffairs() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Elevating ideas to build a better future.</Card.Title>
         <Card.Text><p>The communications landscape is increasingly complex,
         and the world of policy and politics is highly polarised. To have
         impact with policy makers and their influencers, you need a partner
         with vision, expertise, and creativity. You need Global Partners.</p>
        <p>From countries to corporations, associations to agencies, and think
        tanks to thought leaders, the Global Partners public affairs team helps
        clients navigate the intersection of government, influencers, and policymakers,
        to promote ideas that will shape the future.</p>
        <p>Our experts will work seamlessly with your organisation to meet your unique
        needs — from critical issues in your sector, to national and global
        conversations — always with an eye on the goal of creating positive impact.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/PublicA-ffairs-200.jpg"}/>
         <Card.Text>
           <p>Global Partners has the proven ability to build reputation and share of voice
           on policy issues, legislation, and regulation, and the capabilities to drive
           thought leadership and political influence — even in today’s crowded and
           challenging environment.</p>
           <p>Our team leads powerful issues and advocacy campaigns across sectors that include:</p>
           <ul>
            <li>Technology</li>
            <li>Environment</li>
            <li>Education</li>
            <li>Arts and culture</li>
            <li>Trade and the economy</li>
            <li>Consumer rights/welfare</li>
            <li>Transport</li>
            <li>Healthcare</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>

    </Container>
  )
}

export default PublicAffairs;
