import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Associations() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title><b>Building brand strength and growing membership.</b></Card.Title>
         <Card.Text><p>As a leading nonprofit marketing agency, Global Partners&apos; team has a decades-long history of giving associations and nonprofits everything they need— endless support to create and sustain their brands, find and grow membership, and promote their missions and educational opportunities.</p>
          <p>Whether you’re large or small, an international powerhouse or a rising star, our empathy and understanding run deep. We know the challenges you face and the great potential you possess, and we put that understanding to use as a digital marketing agency for nonprofits.</p>
          <p>We use our proven strategies, insights, and campaign tactics to drive awareness and appreciation, turning members and partners into lifelong advocates.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/mybsi-hero.jpg"}/>
         </Card.Body>
         <Card.Body>
          <Card.Title><b>Humanizing branding for BSI, a leading standards body.</b></Card.Title>
          <Card.Text>
           Over many years, BSI had become known for the extensive amount of ISO standards it published — more than 60,000 standards, in fact, for almost every conceivable business practice. But BSI is much more than a standards body, and it needed creative, strategic help promoting its many services and telling its story. That&apos;s when BSI turned to Global Partners.
          </Card.Text>
          <Card.Text>
            Global created a strategy to help humanize BSI, giving a face and warm personality to the company&apos;s complex capabilities and offerings.
          </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>Tell a compelling, unified story in the US for ONE BSI, whose services include consulting, training, standards certification, product certification, and much more.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Associations</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>Gold MarCom: Integrated Marketing (2019)</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/bsi-goals-100.png"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Text>
         <p>We simplified the BSI story by unifying all of its product offerings under the single umbrella of ONE BSI, and we introduced a human face to all communications — particularly online advertising, landing pages, brochures, and events.</p>
         <p>We also developed a marketing strategy designed to begin a conversation with new audiences showcasing how a portfolio of solutions could help their companies perform better today and continue well into the future.</p>
         <p>Together, these solutions delivered new impressions in a cost-effective strategy, expanded BSI’s channel mix, and established a baseline understanding of how new marketing strategies would perform against their new targeting and Marketing objectives. They also succeeded in driving new leads and elevating awareness of BSI with C-Suite targets.</p>
         </Card.Text>
        </Card.Body>
     </Card>

     </Col>
     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Associations/Nonprofit Brand Strategy</Accordion.Header>
       <Accordion.Body>
        A compelling message, clear mission, and engaging personality — those are the keys to raising awareness and building advantages for your association or nonprofit. We’ll help you do it, through a combination of detailed research and our vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Associations/Nonprofit Digital Marketing</Accordion.Header>
       <Accordion.Body>
        We&apos;ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your association or nonprofit. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Associations/Nonprofit Public Relations</Accordion.Header>
       <Accordion.Body>
         It helps greatly to have a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and growing your association or nonprofit at a grassroots level, locally or around the world.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>
    </Container>
  )
}

export default Associations;
