import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Health() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/Health-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Quickening the pace and pulse of health innovation.</b></Card.Title>
            <Card.Text><p>The pace of health innovation never stops. From new pharmaceutical
            advances to how hospital services are accessed, a dramatic and long-anticipated
            shift in healthcare is happening — and new opportunities are arising.
            Global Partners works across the health ecosystem to catalyze interest and action
            to help our clients achieve their business goals.  </p>
             <p>Named “Healthcare Agency of the Year” by PRovoke Media and HITMC, Global
             Partners delivers a full suite of healthcare marketing agency services. Our teams
             across the globe are fierce champions of health innovation, from introducing medical
             breakthroughs to helping our clients offer life-enhancing care.</p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
          <p>Global Partners serves the full range of the health sector — patient-advocacy,
          payer, policy, provider, and product-innovation — and always creates amazing,
          award-winning brand and corporate advertising and public health campaigns that
          change lives.</p>
          <p>Global Partners delivers excellent support across a range of areas that include:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>Clinical-trial recruitment</li>
           <li>Hospital/Provider communications</li>
           <li>Investor relations</li>
           <li>Issues management</li>
           <li>Key-opinion leader engagement</li>
           <li>Product communications</li>
           <li>Scientific communications and scientific writing </li>
           <li>Strategic Planning</li>
           <li>Thought leadership</li>
           <li>Treatment decisions</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/GettyHealth-200.jpg"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Enhancing quality of life & care for chronic disease patients.</b></Card.Title>
          <Card.Text><p>Belong.Life is a true innovator in the healthcare mobile app space. It created
          huge social networks and navigation apps for cancer, MS, and IBD patients, and the company’s
          anonymous data is used to advance global research into the patient journey. Belong.Life chose
          Global Partners to help it raise awareness of its solutions — and to make sure people coping
          with chronic disease have the best tools available to navigate their treatment.</p>
           <p>Global Partners created a media plan focusing on Belong.Life’s use of AI, its unique approach to
           patient engagement, and how it helps improve the patient journey.</p>
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Amplify Belong.Life’s innovative work amongst patients, healthcare
         professionals, and potential business partners to improve access to
         reliable information and enhance health organizations’ ability to treat patients.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Health</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Belong-Life-goal-image-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           Utilising healthtech PR agency strategies we targeted potential partners and
           consumers through mainstream and patient-centric publications, and we used
           specific trade outlets to reach industry leaders in the healthcare, pharma,
           and hospital industries. Those audiences were reached with around 100 different
           articles, quotes, and thought leadership pieces.
           </Card.Text>
           <Card.Text>
             We also raised the profile of the company and its CEO through strategic
             speaking opportunities and awards, including Modern Healthcare’s “Top 25
             Innovators” and Health Tech Digital’s “Best Use of AI.”
            </Card.Text>
            <Card.Text>
              The digital strategy we applied significantly improved Belong.Life’s
              presence on social media — including outreach to influencers in the cancer
              and MS space — and led to significant month-to-month engagement increases.
             </Card.Text>
             <Card.Text>
              Belong.Life now serves over one million patients in more than 100 countries,
              with its cancer app, MS, IDB, and COVID-19 apps.
             </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Health Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear benefits, and an engaging, encouraging personality —
         those are the keys to raising awareness and building advantages for your health
         sector business. We’ll help you do it, through detailed research and  experience
         in patient engagement and HCP marketing for pharmaceutical, medical device, digital
         health, health IT companies.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Health Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your
         brand values. We build healthcare communications strategies that strike that tone —
         and, if needed, we craft honest responses to protect your brand and give you peace
         of mind. Global Partners&apos; approach helps clients explain the complexities in healthcare,
         including how to respond to non-ending change, physicians, patients, and the healthcare
         community at large.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Health Development, Politics & Public Affairs</Accordion.Header>
       <Accordion.Body>
         <p>Creating an impact and making progress in the health field takes maintaining
         strong relationships with legislators and stakeholders. We can help you build those
         relationships — along with a health public affairs strategy that’s clear and effective.</p>
         <p>Additionally, developing countries are becoming more and more influential in shaping
         international policy debates. We have helped many clients in shaping the development agenda.
         We don’t believe that development is the job of governments alone. Hence, we work actively
         in creating public-private partnerships and, sometimes, multilateral partnerships involving
         several private actors and civil society. We’re experts in innovative finance and access to
         results-based funding.</p>
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Health Digital Marketing</Accordion.Header>
       <Accordion.Body>
         Integrated communications programs that put clients at the
         forefront of healthcare digital marketing is intrinsic to our work.
         We create a customized digital marketing plan to cover all your needs,
         from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Health Investor Relations</Accordion.Header>
       <Accordion.Body>
         Industry innovators trust Global Partners to design investor relations strategies that
         facilitate access to capital from pre-clinical to commercial stage.
         Global Partners ensures effective communications between our clients and the
         financial community. We create IR programs that secure market interest and
         shareholder engagement and continued support.  We plan and support R & D days, non-deal
         roadshows, bank conference meetings, and secure real-time feedback to leverage near-term
         opportunities while optimizing long-term value.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Health Knowledge Transfer & Capacity Building</Accordion.Header>
       <Accordion.Body>
         Many consultancies see their job as creating dependent clients — organisations
         that will need to pay for ever-increasing levels of consultant support. We see
         things differently: if we enable clients to build competence and capacity within
         their own organisations, we think that we will assist organizations in developing
         more and more sophisticated approaches, leveraging greater internal capacity.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>Health Public Relations</Accordion.Header>
       <Accordion.Body>
         Health businesses and institutions can benefit greatly by having a strong, genuine,
         lasting relationship with the media. We’ll help you build that relationship, nurturing
         new networks and growing your health brand in your key markets. Global Partners healthcare
         public relations services position your experts and your company as healthcare leaders. We
         build close and longstanding relationships with all the prominent healthcare media outlets
         and trade associations. These relationships pay enormous dividends to our clients in the form
         of regional, national and global exposure.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
       <Accordion.Header>Health Research & Insights</Accordion.Header>
       <Accordion.Body>
         The health and health care fields are constantly growing and evolving, and success requires
         research and insights your business or institution can rely on. We have the expertise and
         resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
       <Accordion.Header>Health Risk Perception & Crisis Management</Accordion.Header>
       <Accordion.Body>
         We excel at proactive crisis management, which includes forecasting potential disruptions and
         preparing contingency plans to deal with them. In case of an unprepared organisation faced with
         a serious situation, Hyderus steps in to identify the real nature of the current problem,
         implement measures to minimise damage and plan for recovery. We focus on rebuilding the public
         image and reputation of the company.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default Health;
