import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function PaidMedia() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Crafting bespoke media plans to reach your audience with creative messaging to achieve your business goals.</Card.Title>
         <Card.Text><p>Global Partners helps you stand out in today’s highly competitive
         and constantly evolving media landscape. We leverage data to understand audience
         behavior, ensuring your message is delivered to the right audience at the right time.</p>
          <p>With our global team of media experts, we create data-driven omnichannel campaigns
          that are focused on helping you achieve your goals. With both awareness and performance
          expertise at the core, our model flexes seamlessly, tapping into specialized knowledge
          and relationships, and leveraging sophisticated targeting techniques. Our media plans
          are strategically designed to keep you top of mind, establish thought leadership,
          encourage engagement, generate leads, and drive business results.</p>
          <p>We work across sectors including Health, Technology, B2B, Education,
          Nonprofits, Consumer and Travel & Tourism, and combine paid media with the full
          arsenal of integrated capabilities – spanning research, creative, content, influencer,
          and partnerships – to make your media dollars work effectively and efficiently.</p>
          </Card.Text>
            <Card.Img className="nyash" src={"/static/media/PAID-MEDIA-200.jpg"}/>
         <Card.Text>
           <p>Global Partners&apos; paid media practice spans three continents, including major media hubs
           in New York, Boston, Chicago, Nashville, Honolulu, London, and Singapore. We offer
           a full range of paid media services, including planning, buying, management, reporting,
           and custom dashboards. Specialties include:</p>
           <ul>
            <li>Digital media</li>
            <li>Social media</li>
            <li>OTT, CTV, and Instream/Pre-Roll</li>
            <li>Paid Search</li>
            <li>Linear TV</li>
            <li>Terrestrial Radio</li>
            <li>Streaming Audio</li>
            <li>Out of Home</li>
            <li>Print</li>
            <li>Account Based Marketing</li>
            <li>Point of Care Advertising</li>
            <li>Influencer Whitelisting</li>
            <li>Real-time Dashboards</li>
            <li>Media Audits</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>

    </Container>
  )
}

export default PaidMedia;
