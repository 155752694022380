import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

function BrandStrategyDevelopment() {
  return (
    <Container>
     <Row>

     <Row>
       <Col sm className="main_scene2">

        <Card>
        <Card.Body>
         <Card.Title>Expressing your brand&apos;s unique personality.</Card.Title>
         <Card.Text><p>Your business comes down to your brand. It’s how you
          stand out from competitors, reach customers and the media,
          increase sales, and boost revenue. So if you haven’t defined
          your brand, the world won’t see how great you are or all the
          value you can bring.</p>
          <p>Global Partners has the clear vision and deep marketing
          expertise to deliver brand strategy and development that
          can’t be surpassed.</p>
          <p>We’ll help you achieve your business goals by building a rock
          solid brand — which all starts with clearly articulating your
          message, mission, audiences, and key differentiators.</p></Card.Text>
            <Card.Img className="nyash" src={"/static/media/BRAND-STRATEGY-2000.jpg"}/>
         <Card.Text>
           <p>Step 1: Our Global Intelligence team uses their skill, experience
           and advanced research and analytics tools to audit the landscape and
           define the “white space” to which your brand can lay claim.</p>
           <p>Step 2: Our creative talent gets to work developing the assets and messaging that will inform future campaigns and plans — and, of course, drive business success.</p>
           <p>Global Partner’s capabilities include:</p>
           <ul>
            <li>Brand discovery and audit</li>
            <li>Brand personality and identity development</li>
            <li>Visual branding: logo, brand guidelines, colour palette</li>
            <li>Verbal branding: brand voice and tone, value prop, tagline development</li>
            <li>Strategic positioning and messaging</li>
            <li>Audience persona research, identification, and development</li>
           </ul>
         </Card.Text>
         </Card.Body>
        </Card>
        <Card className="main_scene2">
          <Card.Body>
           <Card.Title>Talent management for world menopause month.</Card.Title>
           <Card.Text>For World Menopause Month, we wanted to create a campaign
           that communicated Woolroom’s USP of offering heat regulation benefits
           to women experiencing symptoms of the menopause. To add credibility
           to the campaign, we suggested working with a celebrity brand ambassador
           who was well respected and open with their experience with the menopause.
           </Card.Text>
           </Card.Body>
        </Card>
       </Col>
      </Row>

     </Row>
     <Row>
     <Col xs={12} md={6}>
     <Card className="main_scene2">
       <Card.Body>
       <Card.Title>GOAL</Card.Title>
        <p>To create a campaign that communicated Woolroom’s USP of offering heat regulation benefits to women experiencing symptoms of the menopause, while working with a celebrity brand ambassador to open with their experience with the menopause to raise awareness.</p>
        <Card.Title>SECTOR EXPERTISE</Card.Title>
         <p>Consumer – Lifestyle</p>
       </Card.Body>
     </Card>

     </Col>

       <Col xs={12} md={6}>
       <Card className="main_scene2">
        <Card.Body>
          <Card.Img variant="top" src={"/static/media/Woolroom-Menopause-Month.jpg"} />
        </Card.Body>
       </Card>

       </Col>
     </Row>
     <Row>
     <Col>
     <Card className="main_scene2">
     <Card.Title className="main_scene2">The Work</Card.Title>
      <Card.Body>
        <Card.Title>The Global Partners team conducted an analytical review
        of appropriate talent and suggested Louise Minchin be Woolroom’s Brand
        Ambassador for the campaign.</Card.Title>
        <Card.Text>During the activity, the team implemented a content plan
        consisting of media, social and internal comms outreach which included
        a press event, national survey, radio day, photo shoot, exclusive
        interview, Q&A and social posts over the course of World Menopause Month. </Card.Text>
      </Card.Body>
     </Card>

     </Col>
     </Row>

    </Container>
  )
}

export default BrandStrategyDevelopment;
