import React from 'react';
//import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';


function AppLayout() {
  return (
    <Container className="container1">
    <Row>

       <Row>
         <Col sm className="main_scene2">
          <Card>
          <Card.Img variant="top" src={"/static/media/Nominet-pattern-cupcakes.jpg"}/>
          <Card.Body>
           <Card.Title>At Global Partners.</Card.Title>
           <Card.Text><b>we know</b> that untapped opportunity lies in decoding the complex. By solving complicated challenges, we find a real point of difference for your brand to deliver exceptional growth programs and release opportunity. </Card.Text>
           </Card.Body>
          </Card>
         </Col>
       </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/Advertising.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title><b>Advertising</b></Card.Title>
         <Card.Text>Advertising isn’t just about capturing mindshare
         and turning heads — it’s about winning hearts. Great brands
         use great ads to compel people to sit up, listen, and invest
         in the product or service. But where do those brands turn for
         great ad ideas and execution?</Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/advertising">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/BRAND-STRATEGY-2000.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>Brand Strategy & Development</b></Card.Title>
           <Card.Text>Expressing your brand&apos;s unique personality.
            Your business comes down to your brand. It’s how you
            stand out from competitors, reach customers and the media,
            increase sales, and boost revenue. So if you haven’t defined
            your brand, the world won’t see how great you are or</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/brand-strategy-development">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Content-Marketing-21191.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>Content Marketing</b></Card.Title>
           <Card.Text>Creating optimised content that delivers.
           It’s no big secret: Content is king. Content fuels
           the digital marketing tactics that increase customer engagement,
           loyalty that drives business results and enables you to stand out
           from the crowd. In the age of disruptive advertising and</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/content-marketing">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
      <Card.Img variant="top" src={"/static/media/Corporate-Communications-116369.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title><b>Corporate Communications</b></Card.Title>
         <Card.Text>Crafting corporate PR that drive positive perceptions.
         Companies today have to do more than deliver a product
          or service. They have to take a stand on important issues — that’s
          what customers, partners, and employees expect. But communicating your
          company’s principles</Card.Text>
         </Card.Body>
         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/corporate-communications">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
         <Card.Img variant="top" src={"/static/media/Digital-Marketing-200.jpg"} className="img_thumb" />
            <Card.Body>
           <Card.Title><b>Digital Marketing</b></Card.Title>
           <Card.Text>Creating digital marketing strategies that drive performance.
           Brands and organisations are increasingly turning to
           digital marketing as an overarching strategy for building brand
           awareness, acquiring new customers and growing sales revenues.
           To win in </Card.Text>
           </Card.Body>
           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/digital-marketing">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
         <Card.Img variant="top" src={"/static/media/Influencer-Marketing-20.jpg"} className="img_thumb" />
            <Card.Body>
           <Card.Title><b>Influencer Marketing</b></Card.Title>
           <Card.Text>Engaging the right people to tell your story the right way.
           Expertly crafted content, authentic storytelling,
           and a knack for capturing and persuading digital followers — that’s
           what great influencers do. We know the right ones (or we’ll find
             them) to excite and engage your target audience.
           </Card.Text>
           </Card.Body>
           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/influencer-marketing">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/PAID-MEDIA-200.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title><b>Paid Media</b></Card.Title>
         <Card.Text>Crafting bespoke media plans to reach your audience with creative messaging to achieve your business goals.
         Global Partners helps you stand out in today’s highly competitive
         and constantly evolving media landscape. We leverage data to understand audience
         behavior
         </Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/paid-media">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/PublicA-ffairs-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>Public Affairs</b></Card.Title>
           <Card.Text>Elevating ideas to build a better future. The communications landscape is increasingly complex,
           and the world of policy and politics is highly polarised. To have
           impact with policy makers and their influencers, you need a partner
           with vision, expertise, and creativity.</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/public-affairs">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/public-relations-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>Public Relations</b></Card.Title>
           <Card.Text>Telling brand stories in memorable, masterful ways.
           What makes a public relations campaign successful? When your
           product or service aligns with what your audience wants and needs — and you
           communicate that unique value to them effectively.</Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/public-relations">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>
      <Row>
      <Col xs={12} md={4}>
      <Card className="main_scene2">
        <Card.Img variant="top" src={"/static/media/Research-Insights-200.jpg"} className="img_thumb" />
        <Card.Body>
         <Card.Title>Research & Insights</Card.Title>
         <Card.Text>Deeply understanding what drives your audience.
         For you to connect with your key audiences and stakeholders,
         you have to understand them as people. What do they think? What do they want?
         What issues do they face? And what would compel them to take certain actions?
          </Card.Text>
         </Card.Body>

         <Card.Footer className="FirstPageCard">
         <Button variant="primary">
           <Nav.Link href="/research-insights">Go There</Nav.Link>
         </Button>
         </Card.Footer>
      </Card>

      </Col>
        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/SEO-strategies-200.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title><b>SEO</b></Card.Title>
           <Card.Text>Utilizing SEO strategies to grow your brand&apos;s visibility online.
           We understand what is required to deliver a successful
            and sustainable SEO campaign which will take you to the top of the
            organic search results. A carefully considered blend of engaging, relevant content,
            technical
           </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
             <Nav.Link href="/seo-strategies">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>

        <Col xs={12} md={4}>
        <Card className="main_scene2">
          <Card.Img variant="top" src={"/static/media/Jordan-Hero-100.jpg"} className="img_thumb" />
          <Card.Body>
           <Card.Title>Travel Trade Representation</Card.Title>
           <Card.Text><b>Our expertise in destination representation</b>,
           span the globe. As the in-market liaison for international
           tourist offices, our experience is unrivalled. Our expert
           teams integrate your sales, marketing and communications
           efforts while leveraging the full range of </Card.Text>
           </Card.Body>

           <Card.Footer className="FirstPageCard">
           <Button variant="primary">
            <Nav.Link href="/travel_trade_representation">Go There</Nav.Link>
           </Button>
           </Card.Footer>
        </Card>

        </Col>
      </Row>

      </Row>
    </Container>
  );
}

export default AppLayout;
