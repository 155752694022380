import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function ProfessionalServices() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/Professional-Services-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>Get seen and chosen, even in a crowded field.</b></Card.Title>
            <Card.Text><p>To stand out and stay successful, professional services leaders have to
            find new ways to connect their brands with opportunities and to demonstrate their value.
            It seems straightforward, but it’s a real challenge — especially when so many firms look,
            feel, and sound alike.</p>
             <p>Global Partners has a professional services PR and integrated marketing team that can
             help you clearly define, communicate, and demonstrate what makes you different in a crowded
             marketplace.</p>
             <p>We deliver effective, creative solutions to clients in numerous professional services sectors,
             such as accounting, architecture, consulting, law, wealth management, and more.</p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

       <Card className="main_scene2">
        <Row>

         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>THE KING’S SPEECH 2024 – SETTING THE NEW GOVERNMENT AGENDA</b></Card.Title>
          <Card.Text>
           The King’s Speech announces the main policy priorities of a new parliamentary session.
           As the first King’s Speech of the newly elected Labour government, the 40 bills and draft
           bills introduced yesterday represented an opportunity for the Prime Minister and his Cabinet
           to outline the priorities that will deliver the change promised during the election campaign –
           setting the tone for the next five years.
          </Card.Text>
          <Card.Text><p><b>Key bills and reforms</b></p>
          <p>The <b>National Wealth Fund Bill</b>, the <b>Planning and Infrastructure Bill</b>, the <b>English Devolution Bill</b>,
          the <b>Great British Energy Bill</b>, and the <b>Crown Estate Bill</b> will enable the government to achieve its
          most pressing reforms to drive economic growth: streamlining planning rules for building more homes
          and infrastructures; setting up a public-owned company, Great British Energy, to boost investment in
          renewable energy projects, including offshore wind; devolving powers to give mayors more control over
          planning and transport. The <b>Passenger Railway Services (Public Ownership) Bill</b> and the <b>Rail Reform Bill</b>
          will also play a role in the government’s mission to deliver growth.</p>
          <p>The introduction of the <b>Cyber Security and Resilience Bill</b> reflects the government’s concerns about the importance of
          protecting infrastructure and services offered by an increasingly digital economy. The <b>Employment Rights Bill</b> will enact
          some policies detailed in the New Deal for Working People, published in May, including, amongst others, banning exploitative
          zero-hour contracts, reforming the law to end ‘fire and rehire’ and ‘fire and replace’ practices, making flexible working the
          default from day one for all workers, and removing unnecessary restrictions on trade union activity introduced by the Conservative
          government. The government has promised to work in consultation with businesses to ensure the deal is fair for everyone.</p>
          </Card.Text>
          <Card.Text><p><b>Financial and economic policies</b></p>
           <p>An important piece of legislation that will be introduced as a draft bill, the <b>Draft Audit Reform and Corporate Governance Bill</b>,
           will address longstanding concerns about audit quality and the lack of competition within the market, in an effort to encourage investment
           in UK companies.</p>
           <p>As trialled over the past few weeks, the <b>Budget Responsibility Bill</b> introduces legislation to empower the Office for Budget
           Responsibility (OBR), the economic watchdog, to publish independent forecasts of major fiscal events to avoid a repeat of Liz Truss’
           2022 mini-budget, delivered without an independent assessment from the OBR. The <b>Bank Resolution (Recapitalisation) Bill</b> will expand
           the statutory role of the Financial Services Compensation Scheme (FSCS) and give the Bank of England greater flexibility when responding to
           the failure of small banks.</p>
          </Card.Text>
          <Card.Text>
           In an attempt to reset the UK’s relationship with the European Union (EU), the government has signalled that it will seek a new security pact
           to strengthen cooperation on mutual threats. However, one of the main barriers to a smoother trade relationship with the EU is the regulatory
           (or lack of) alignment with the EU Single Market. The <b>Product Safety and Metrology Bill</b> will make it easier for the UK to recognise new EU
           product regulations to protect businesses.
          </Card.Text>
          <Card.Text>
           The government has also decided to pick up three bills introduced by the previous government on phasing out a ban on smoking, giving renters more
           rights, and the creation of a new football regulator in England.
          </Card.Text>
         </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Title><b>How your company should prepare for the months ahead</b></Card.Title>
           <Card.Img className="nyash" src={"/static/media/GettyImages-304520-0.jpg"}/>
           <Card.Text>
            <ol>
             <li>The King’s Speech doesn’t restrain the government so other legislation can be introduced before the end of this parliamentary session. Some measures contained in the manifesto have been parked for now, for example, the introduction of restrictions on visas for foreign workers. Continue to monitor announcements and conversations about your industry</li>
             <li>Bills can be introduced to Parliament from today. Some bills will be published before the summer break, but we should not expect them to make much progress until at least September when Parliament comes back from recess – before the party conference season starts. Companies should use this time to identify the areas they want to focus on and work on suggesting solutions, and improvements, as well as bringing up potential issues.</li>
             <li>Remember that the bills will be subject to parliamentary security and discussion, and stakeholders can shape the debate and suggest amendments. Be ready for when the time comes so you can contribute in a meaningful way.</li>
             <li>Start preparing for the Autumn Statement. Although we don’t have a date yet, the OBR needs at least 10 weeks to draw up its independent assessment of any future tax and spending changes. This means the budget will be delivered in mid-September at the earliest, or after the party conference season in November.</li>
             <li>The Labour Party Conference will represent an opportunity for businesses and organisations to engage with the government and other stakeholders. Consider attending and hosting an event to discuss your priorities.</li>
            </ol>
           </Card.Text>
          </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2"><b>Get in touch with Global Partners public affairs</b></Card.Title>
       <Card.Body>
          <Card.Text>
           Please do get in touch if you would like to discuss how the public affairs team can support your
           business. Contact Daniella Tomasini, Vice-President, London Office: dani.tomasini@altopek.com
           </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

      <Card className="main_scene2">
       <Row>

       <Col xs={12} md={6}>
        <Card.Body>
         <Card.Text>
          Global Partners knows like no other agency how to make sure that you’re discovered, considered, and chosen. We get that done through a wide array of services that include:
        </Card.Text>
        </Card.Body>
       </Col>
       <Col xs={12} md={6}>
        <Card.Body>
        <Card.Text>
        <ul>
         <li>Brand strategy</li>
         <li>Design</li>
         <li>Websites</li>
         <li>Content marketing</li>
         <li>Video</li>
         <li>PR</li>
         <li>Advertising</li>
         <li>Thought leadership</li>
         <li>Lead-generation campaigns</li>
        </ul>
        </Card.Text>
        </Card.Body>
       </Col>

      </Row>
     </Card>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Professional Services Advertising</Accordion.Header>
       <Accordion.Body>
         We have unmatched expertise in reaching audiences with memorable, effective messaging. We’ll use data-driven tactics to massively increase your professional services brand awareness and drive sales and revenue — to maximize the ROI of your ad efforts.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Professional Services Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         The professional services space is crowded, and standing out from your competition takes a compelling message, clear market position, and engaging personality. We use detailed research and vast experience to help you build your brand.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Professional Services Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your professional services organisation. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Professional Services Public Relations</Accordion.Header>
       <Accordion.Body>
         Your professional services business and brand will greatly benefit from having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and enhancing your efforts anywhere in the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Professional Services Research & Insights</Accordion.Header>
       <Accordion.Body>
         The professional services space is always shifting and evolving, and navigating it requires research and insights your institution can rely on. We have the expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>

     </Accordion>

    </Container>
  )
}

export default ProfessionalServices;
