import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function Technology() {
  return (
    <Container>
     <Row>


        <Card className="main_scene2">
         <Row>
         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Img className="nyash" src={"/static/media/Tech-200.jpg"}/>
          </Card.Body>
         </Col>
          <Col xs={12} md={6}>
           <Card.Body>
            <Card.Title><b>High-touch communications for a high-tech world.</b></Card.Title>
            <Card.Text><p>Technology has fundamentally changed the way we engage with the world
            and each other — both in business and in life. That’s why every organisation in every
            sector, from healthcare to financial services, is seeking the right technology partners
            on their journey of innovation-powered experiences and possibilities.</p>
             <p>Global Partners is one of the top technology PR companies with three global technology
             teams. We use our deep expertise and understanding of tech trends to help clients of all
             kinds transform their brands, build their ecosystems and become trusted drivers of digital
             transformation through strategic communications programmes.</p>
            </Card.Text>
           </Card.Body>
          </Col>
         </Row>

        </Card>

        <Card className="main_scene2">
         <Row>

         <Col xs={12} md={6}>
          <Card.Body>
           <Card.Text>
          <p>The drive for technology innovation impacts countless businesses,
          such as: enterprise and consumer tech giants who must re-establish their
          relevance; innovative startups who must demonstrate their credibility and
          value; and non-traditional tech companies needing to communicate their
          technology platform.</p>
          <p>Our award-winning technology team serves them all by pairing seasoned,
          professional communications leadership with a data-driven mindset, and the
          right expertise across critical breakthroughs in key areas, including:</p>
          </Card.Text>
          </Card.Body>
         </Col>
         <Col xs={12} md={6}>
          <Card.Body>
          <Card.Text>
          <ul>
           <li>5G connectivity</li>
           <li>Cybersecurity</li>
           <li>Artificial intelligence</li>
           <li>Enterprise software</li>
           <li>Supply chain</li>
           <li>Virtual and augmented reality</li>
           <li>Machine learning</li>
           <li>Robotic process automation</li>
           <li>Blockchain</li>
           <li>Quantum computing</li>
           <li>Gaming</li>
           <li>Consumer electronics</li>
           <li>B2B</li>
          </ul>
          </Card.Text>
          </Card.Body>
         </Col>

        </Row>
       </Card>

       <Card className="main_scene2">
        <Row>
        <Col xs={12} md={6}>
         <Card.Body>
          <Card.Img className="nyash" src={"/static/media/Blackmore-hero-200.png"}/>
         </Card.Body>
        </Col>
         <Col xs={12} md={6}>
         <Card.Body>
          <Card.Title><b>Restoring faith in an auto tech innovator.</b></Card.Title>
          <Card.Text>
            The automotive tech industry is hotter than ever, and Blackmore needed to clarify a few things. After all, Elon Musk had just declared that any autonomous vehicle relying on Blackmore’s technology — light detecting and ranging sensors (aka lidar) — is doomed. So the company had to make its case and make its value clear, to both automotive and business audiences. That’s when Blackmore turned to Global Partners for creative solutions to dispel myths and change skeptics’ minds.
           </Card.Text>
           <Card.Text>
             Global Partners formed a plan to tell Blackmore’s story as a scrappy upstart from Montana, challenging big city competitors with the deep pockets.
           </Card.Text>
         </Card.Body>
         </Col>
        </Row>

       </Card>

      <Row>
      <Col xs={12} md={6}>
      <Card className="main_scene2">

        <Card.Body>
        <Card.Title>GOAL</Card.Title>
         <p>Create a sales pipeline for Blackmore and secure a capital infusion, while earning trust with investors and consumers.</p>
         <Card.Title>SECTOR EXPERTISE</Card.Title>
          <p>Technology</p>
         <Card.Title>ACCOLADES</Card.Title>
          <p>2020 Silver Anvil</p>
        </Card.Body>
      </Card>

      </Col>

        <Col xs={12} md={6}>
        <Card className="main_scene2">
         <Card.Body>
           <Card.Img variant="top" src={"/static/media/Blackmore-Goal-100.jpg"} />
         </Card.Body>
        </Card>

        </Col>
      </Row>

      <Row>
      <Col>
      <Card className="main_scene2">
      <Card.Title className="main_scene2">The Work</Card.Title>
       <Card.Body>
          <Card.Text>
           Our research revealed that three narratives drove nearly all news — funding, new products, and partnerships and sales. Blackmore had 3% share of voice, and none of these news drivers. So, inspired by “seeing is believing,” Global Partners quickly deployed a three pillared strategy.
           </Card.Text>
           <Card.Text>
             The first step was to reboot Blackmore’s visibility by targeting particular reporters — those with the platform to reach investors and the technical chops to understand its nuances.
            </Card.Text>
            <Card.Text>
              Then, we reached engineering and technical audiences responsible for ensuring AV’s safety by establishing Blackmore’s lidar as a viable alternative.
             </Card.Text>
             <Card.Text>
              Finally, we made sure Blackmore got noticed by potential customers, analysts and reporters via pursuing validating awards and speaking opportunities.
             </Card.Text>
             <Card.Text>
              The result? Beyond a 14% increase in share of voice, the self-driving
              Aurora used some of its $530M funding from Amazon to acquire Blackmore.
              And we earned powerful coverage. This Forbes headline says it all,
              <a href="https://www.forbes.com/sites/bradtempleton/2019/05/24/aurora-buys-high-performance-lidar-firm-blackmore-a-vote-for-lidar-almost-in-rebuke-to-elon-musk/#7e00e45f607d"> “Aurora Buys High Performance Lidar Firm Blackmore, A Vote for Lidar,
              Almost in Rebuke to Elon Musk.”</a>
             </Card.Text>
         </Card.Body>
      </Card>

      </Col>
      </Row>

     </Row>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
       <Accordion.Header>Tech Advertising</Accordion.Header>
       <Accordion.Body>
         We have unmatched expertise when it comes to advertising technology to the right audience and market. We’ll use data-driven tactics to increase your brand awareness and hit your goals — thus maximizing the ROI of your ad efforts.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
       <Accordion.Header>Tech Brand Strategy & Development</Accordion.Header>
       <Accordion.Body>
         A compelling message, clear mission, and engaging personality — that’s how a tech product or service stands out from the competition and succeeds in a crowded market. We’ll help you do it, through a combination of detailed research and vast experience.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
       <Accordion.Header>Tech Content Marketing</Accordion.Header>
       <Accordion.Body>
         Driving traffic to your site takes relevant tech content that’s high quality. It has to be interesting, insightful, and trustworthy — and that’s what we deliver.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
       <Accordion.Header>Tech Corporate Communications</Accordion.Header>
       <Accordion.Body>
         Your voice and tone have to be consistent, compelling, and always aligned to your brand values. We build communication strategies that strike that tone — and, if needed, we craft honest responses to protect your brand and give you peace of mind.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
       <Accordion.Header>Tech Digital Marketing</Accordion.Header>
       <Accordion.Body>
         We’ll rely on extensive research, best practices, and burgeoning trends to create a digital marketing plan customized to your tech company. Our integrated approach will cover all your needs, from video content to social media.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
       <Accordion.Header>Tech Influencer Marketing</Accordion.Header>
       <Accordion.Body>
         Influencer marketing can be highly effective, but it takes finding the influencer who matches your product or service with the right personality and style. We have close relationships with numerous influencers who can help you hit your goals.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
       <Accordion.Header>Tech Public Affairs</Accordion.Header>
       <Accordion.Body>
         The tech sector impacts us on a societal level, and making progress requires building and maintain strong relationships with legislators and stakeholders. We have the experience in the tech space to help you create a strong public affairs strategy. some agencies to be a great place to work, but we want to be the best.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
       <Accordion.Header>Tech Public Relations</Accordion.Header>
       <Accordion.Body>
         Tech brands and companies can benefit greatly by having a strong, genuine, lasting relationship with the media. We’ll help you build that relationship, nurturing new networks and fueling your growth in target markets around the world.
       </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
       <Accordion.Header>Tech Research & Insights</Accordion.Header>
       <Accordion.Body>
         Tech is a fast-moving, ever-evolving industry, and succeeding in it requires research and insights you can rely on. FINN Partners has the deep expertise and resources to conduct market research and deliver insights on a global scale.
       </Accordion.Body>
      </Accordion.Item>
     </Accordion>

    </Container>
  )
}

export default Technology;
